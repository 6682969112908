import React, {useState} from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';

export default function UserTypeCard({ ...props }) {

  const [selected, setSelected] = useState(false);

  const onClick = () => {
    setSelected(true);
    setTimeout(() => {
      props.onStepUserTypeClick(props.title);
    }, 200);
  };
  
  return (
    <Card onClick={onClick} className={ selected ? 'card-usertype card-selected': 'card-usertype' }>
      <Card.Header style={{'borderBottom': '0'}}>
          <h2 className="card-header-title">{props.title}</h2>
      </Card.Header>
      <Card.Img className='mx-auto d-block' style={{maxWidth: '70%'}}  variant="top" src={props.image} alt={props.title} />
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h4 className="mb-1">{props.description}</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

// 