import { Button, Col, Nav, Row } from "react-bootstrap";

import UserTypeCard from "../../../widgets/UserTypeCard";

import Clinician from "../../../assets/illustrations/clinicians.svg";
import Developer from "../../../assets/illustrations/developers.svg";

const StepUserType = ({
  activeStep,
  clinic,
  dev,
  onStepUserTypeClick,
}: {
  activeStep: number;
  clinic: any;
  dev: any;
  onStepUserTypeClick: any;
}) => (
  <>
    <Row className="justify-content-center">
      <Col xs={12} md={10} lg={8} xl={6} className="text-center">
        <h6 className="mb-4 text-uppercase text-muted">
          Step {activeStep} of 4
        </h6>
        <h1 className="mb-3">Select Product</h1>
        <p className="mb-5">Choose a product to continue</p>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <UserTypeCard
          onStepUserTypeClick={onStepUserTypeClick}
          image={Developer}
          title={dev}
          description="For Health IT, Software Engineers, and App Developers."
        />
      </Col>
      <Col xs={12} md={6}>
        <UserTypeCard
          onStepUserTypeClick={onStepUserTypeClick}
          image={Clinician}
          title={clinic}
          description="For medical professionals and support staff."
        />
      </Col>
    </Row>
    <hr className="mt-5 mb-5" />
    <Nav className="row align-items-center">
      <Col className="text-center">
        <h6 className="text-uppercase text-muted mb-0">
          Step {activeStep} of 4
        </h6>
      </Col>
    </Nav>
  </>
);

export default StepUserType;
