const ResultCell = ({ title, value }: { title: string; value: string }) => (
  <div style={{ display: "flex", flexDirection: "column", maxWidth: "300px", marginRight: '16px', flexBasis: "45%" }}>
    <p style={{ fontWeight: "bold", marginTop: "0", marginBottom: "8px" }}>
      {title}:
    </p>
    <div
      style={{
        background: "rgba(22, 27, 45, 0.1)",
        minHeight: "80px",
        padding: "12px",
        marginBottom: "16px" 
      }}
    >
      {value}
    </div>
  </div>
);

const ResultsTable = ({
  payerName,
  planName,
  memberNumber,
  groupNumber,
  member,
  dependents,
  rxbin,
  rxPCN,
}: {
  payerName: string;
  planName: string;
  memberNumber: string;
  groupNumber: string;
  member: string;
  dependents: string;
  rxbin: string;
  rxPCN: string;
}) => (
  <div style={{ display: "flex", flexWrap: "wrap" }}>
    <ResultCell title="Payer Name" value={payerName} />
    <ResultCell title="Plan Name" value={planName} />
    <ResultCell title="Member Number" value={memberNumber} />
    <ResultCell title="Group Number" value={groupNumber} />
    <ResultCell title="Member" value={member} />
    <ResultCell title="Dependents" value={dependents} />
    <ResultCell title="RxBin" value={rxbin} />
    <ResultCell title="RxPCN" value={rxPCN} />
  </div>
);

export default ResultsTable;
