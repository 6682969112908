import { Image, Flex, Heading, useTheme } from "@aws-amplify/ui-react";
import Logo from "../../assets/icons/CardScanLogo.svg";

export default function AuthHeader() {
    const { tokens } = useTheme();
  
    return (
      <Flex justifyContent="center">
        <Image
          alt="logo"
          src={Logo}
          padding={tokens.space.medium}
          width={'125px'}
        />
      </Flex>
    );
  }
  