import { Dispatch, FC, SetStateAction } from "react";
import FeatherIcon from "feather-icons-react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Nav,
  Row,
  Form,
} from "react-bootstrap";

const Header: FC<{
  activeTab: 0 | 1;
  setActiveTab: Dispatch<SetStateAction<0 | 1>>;
  handleSelect: (x: string | null) => void;
  statusQuantities: [number, number, number, number];
  setIsSandbox: Dispatch<SetStateAction<boolean>>;
  disabledProd: boolean;
  isSandbox: boolean;
}> = ({
  activeTab,
  setActiveTab,
  handleSelect,
  statusQuantities,
  isSandbox,
  setIsSandbox,
  disabledProd,
}) => (
  <div className="header">
    <div className="header-body">
      <Row className="align-items-center">
        <Col>
          <h6 className="header-pretitle">Overview</h6>
          <h1 className="header-title">Cards</h1>
        </Col>
        <Col xs="auto">
          <div className="form-check form-switch card__switch">
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Sandbox mode
            </label>
            <Form.Check
              type="switch"
              onChange={(e) => setIsSandbox(e.target.checked)}
              checked={isSandbox}
              disabled={disabledProd}
              inline
              id="flexSwitchCheckDefault"
            />
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <Nav
            variant="tabs"
            defaultActiveKey="completed"
            onSelect={handleSelect}
            className="header-tabs nav-overflow"
          >
            <Nav.Item>
              <Nav.Link
                eventKey="completed"
                className="text-nowrap"
                role="button"
              >
                Completed{" "}
                <Badge bg="secondary-soft" className="rounded-pill">
                  {statusQuantities[0]}
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="all" className="text-nowrap" role="button">
                All{" "}
                <Badge bg="secondary-soft" className="rounded-pill">
                  {statusQuantities[1]}
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="processing"
                className="text-nowrap"
                role="button"
              >
                Processing{" "}
                <Badge bg="secondary-soft" className="rounded-pill">
                  {statusQuantities[2]}
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="error" className="text-nowrap" role="button">
                Errors{" "}
                <Badge bg="secondary-soft" className="rounded-pill">
                  {statusQuantities[3]}
                </Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs="auto">
          <ButtonGroup className="nav d-inline-flex">
            <Button
              variant="white"
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              <FeatherIcon icon="list" size="1em" />
            </Button>
            <Button
              variant="white"
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              <FeatherIcon icon="grid" size="1em" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  </div>
);

export default Header;
