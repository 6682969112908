import { useContext } from "react";
import APIKeyContext, { iAPIKeys } from "../../context/APIKeyContext";
import "./apiKeys.css";


type APIKeyProps = {
  apiKeys: iAPIKeys
}

const APIKeyView = ({}) => {
  const { apiKeys } = useContext(APIKeyContext);
  const sandboxKey =  apiKeys ? apiKeys.sandbox : '';
  const prodKey = apiKeys ? apiKeys.production : ''; 


  const apiCard = (key: string, keyName: string) => {
    return (
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="text-uppercase text-muted mb-2">
                  {keyName}
                </h6>
                <span className="h2 mb-0 text-monospace">{key ? key : 'Contact Support'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
        <div className="alert alert-secondary" role="alert" hidden={prodKey === '' ? false: true}>
          <i className="fe fe-help-circle"></i> Production keys are only available with an active subscription
        </div>
        <div data-private className="row">
          {apiCard(sandboxKey, 'Sandbox')}
          {apiCard(prodKey, 'Production')}
        </div>
    </div>
  );
};

export default APIKeyView;
