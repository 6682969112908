import React, { SetStateAction, Dispatch } from "react";

export interface iAPIKeys {
  sandbox: string,
  production: string
}

const APIKeyContext = React.createContext<{
  apiKeys: iAPIKeys;
  setApiKeys: Dispatch<SetStateAction<iAPIKeys>>;

}>({ 
  apiKeys: {sandbox: '', production: ''},
  setApiKeys: () => {}});
  
export const hasKeys = (apiKeys: any): Boolean => {
  return (apiKeys && apiKeys.sandbox);
}

export default APIKeyContext;
