import { Col, Row } from "react-bootstrap";
import AccountTwoFactorSecurity from "./AccountTwoFactorSecurity";
import AccountChangeYourPassword from "./AccountChangeYourPassword";

const Security = () => {
  return (
    <div className="main-content">
      <Row>
        <Col xs={12} >
          <AccountTwoFactorSecurity />
          <hr className="my-5" />
          <AccountChangeYourPassword />
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default Security;
