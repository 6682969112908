import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation, useSearchParams, Navigate } from "react-router-dom";
import SignUp from "../components/Auth/SignUp";
import SignIn from "../components/Auth/SignIn";
import Onboarding from "../components/Onboarding/onboarding";
import CardScanApiContext from "../context/cardScanApiContext";
import Home from "../components/Home";
import AccountSetup from "../components/Onboarding/accountsetup";
import Stripe from "../components/Stripe/index";
import Cards from "../components/Cards";
import { CardScanView } from "@cardscan.ai/insurance-cardscan-react";
import Layout from "../components/Layout";
import CardContext from "../context/cardContext";
import APIKeyContext from "../context/APIKeyContext";
import LogRocket from 'logrocket';
import AccountView from "../components/Account";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { RequireAuth } from "../components/Auth/RequireAuth";


const AppRoutes: React.FunctionComponent = () => {
  const { route, user } = useAuthenticator((context) => [context.route]);
  const [apiKeys, setApiKeys] = useState<any>(null);
  const [cardDetails, setCardDetails] = useState<any>(null);
  const [cardImage, setCardImage] = useState("");
  const [cardScanApi, setCardScanApi] = useState<any>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams]  = useSearchParams();

    useEffect(() => {
      if (!user) {
        return
      }
 
      if (route != 'authenticated') {
        return
      }

      const token =  user.getSignInUserSession()?.getIdToken().getJwtToken(); 

      fetch(`${process.env.REACT_APP_DASHBOARD_API}/api-keys`, {
        headers: { Authorization: `Bearer ${token}` },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          const prod = data.filter((item: any) => item.type === "secret_live");
          const sand = data.filter((item: any) => item.type === "secret_test");
          const prodKey = prod.length > 0 ? prod[0].api_key : "";
          const sandKey = sand.length > 0 ? sand[0].api_key : "";
        
          if(sandKey){
            setApiKeys({ sandbox: sandKey, production: prodKey });
          }else{
            navigate("/setup?step=final");
          }
        
        })
        .catch((err) => {
          setApiKeys({ sandbox: "", production: "" });
          console.log(err);
        });
    }, [setApiKeys, user, route]);


    useEffect(() => {
      if (!user) {
        return
      }
      const attributes = user.attributes;
      if (!attributes) {
        return
      }

      try {
        if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {    
          LogRocket.identify(attributes.sub, {
            email: attributes.email,     
            accountId: attributes['custom:account_id']
          });
        }        
      } catch (error) {
        console.error(error);
      }
    }, [user]);

    return (
      <APIKeyContext.Provider value={{ apiKeys, setApiKeys }}>
        <CardContext.Provider
          value={{ cardDetails, setCardDetails, cardImage, setCardImage }}
        >
          <CardScanApiContext.Provider value={{ cardScanApi, setCardScanApi }}>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/scan" element={
              <RequireAuth>
                <CardScanView
                  sessionToken={apiKeys ? apiKeys.sandbox : ""}
                  live={false}
                  onSuccess={(cardDetails) => {
                    setCardDetails(cardDetails);
                    setCardImage(cardDetails.images?.front.url || "");
                    navigate("/demo");
                  }}
                  onCancel={() => navigate("/demo")}
                  onError={() => {}}
                  debug={searchParams.get("debug") ? true : false}
                />
              </RequireAuth>
            } />
            <Route path="/keys" element={
              <Navigate to="/account/keys" replace />
            } />
            <Route path="/demo" element={
              <RequireAuth>
                <Layout>
                  <Home />
                </Layout>
              </RequireAuth>
            } />
            <Route path="/onboarding" element={
              <RequireAuth>
                <Navigate to="/setup" replace />
              </RequireAuth>
            } />
            <Route path="/setup" element={
              <RequireAuth>
                <AccountSetup />
              </RequireAuth>
            } />
            <Route path="/cards" element={
              <RequireAuth>
                <Layout>
                  <Cards />
                </Layout>
              </RequireAuth>

            } />
            <Route path="/stripe" element={
              <RequireAuth>
                <Layout>
                  <Stripe />
                </Layout>
              </RequireAuth>
            } />
            <Route path="/account/:tab" element={
              <RequireAuth>
                <Layout>
                  <AccountView />
                </Layout>
              </RequireAuth>
            } />
            <Route path="/" element={<Navigate to="/demo" replace />} />
            {/* <Route path="*" element={<Navigate to="/demo" replace />} /> */}
          </Routes>
          </CardScanApiContext.Provider>
        </CardContext.Provider>
      </APIKeyContext.Provider>
    );
  };

export default AppRoutes;
