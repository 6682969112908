// import FeatherIcon from 'feather-icons-react';
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useLocalState from "./useLocalState";
import { Col, Container, Row } from "react-bootstrap";
import { log } from "../../widgets/DashboardLog";

import StepUserType from "./steps/StepUserType";
import StepCompanyDetails from "./steps/StepCompanyDetails";
import StepEmail from "./steps/StepEmail";

const clinic = "CardScan Clinic";
const dev = "CardScan APIs";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

interface OnboardingState {
  fromStep: number;
  toStep: number;
}

export default function Wizard() {
  const [email, setEmail] = useLocalState<string | null>("email", null)
  const [emailPassedIn, setEmailPassedIn] = useState(false)

  const query = useQuery();
  const page = query.get("page");

  const [loaded, setLoaded] = useState(false);
  const [storage, setStorage] = useLocalState("formValues", {});

  const [activeStep, setActiveStep] = useLocalState("activeStep", 1);
  const [selection, setSelection] = useLocalState<string | null>(
    "selection",
    null
  );
  const [annualBilling, setAnnualBilling] = useLocalState(
    "annualbilling",
    true
  );
  const { register, handleSubmit, reset, watch, getValues } = useForm();

  const formData = watch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //support startup link, force to begining of flow.
    if (query.get('start')) {
      setActiveStep(1);
      setEmailPassedIn(false);
      setEmail(null)
    }

    if (activeStep === 1) {
      var data = _prepareLogData([])
      log("Step: Onboarding Start", data, null);
    }

  }, []);

  useEffect(() => {
    const state = location.state as OnboardingState || null;
    const toStep = state?.toStep || 1;
    // const action = state?.action;
    const action = "PUSH";

    if (activeStep > (emailPassedIn ? 2 : 3)) {
      setActiveStep((activeStep) => activeStep - 1);
    }

    if (action === 'PUSH') {
      setActiveStep(toStep);
    }

    // if (action === 'POP') {
    //   setActiveStep(Math.max(toStep, 1));
    // }
  }, [location]); // watch the location state

  useEffect(() => {
    var search = new URLSearchParams(location.search);
    var email = search.get('email');

    if (email) {
      // setEmailPassedIn(true);
      setEmail(email)
    }
  }, [location, setEmail, setEmailPassedIn]);

  // useEffect(() => {
  //   //skip selection if clinic is pre-selected
  //   if (page === "clinic" && activeStep === 1) {
  //     setSelection("clinic");
  //     setActiveStep((step) => step + 1);
  //   }

  // }, [page, setSelection, setActiveStep, activeStep]);

  useEffect(() => {
    if (!loaded) {
      reset(storage);
      setLoaded(true);
    }
  }, [storage, reset, loaded]);

  const onChange = () => {
    setStorage(formData);
  };

  const _prepareLogData = (data: any) => {

    data.push({ 'title': 'search', 'value': location.search });

    if (email) {
      data.push({ 'title': 'email', 'value': email });
    } else {
      //extract email on landing...
      var search = new URLSearchParams(location.search);
      var _email = search.get('email');

      if (_email) {
        data.push({ 'title': 'email', 'value': _email });
      }
    }
    return data;
  }

  const onStepUserTypeClick = (_selection: any) => {
    setSelection(_selection);

    var data = [{ title: "selection", value: _selection }];

    data = _prepareLogData(data);
    log("Step: User Type Selection", data, null);

    var search = '';
    if (email) {
      search = 'email=' + encodeURIComponent(email)
    }

    var _state = { 'fromStep': activeStep, 'toStep': activeStep + 1 }
    navigate(`/onboarding?${search}`, { state: _state });

    return false;
  };

  const onStepCompanyDetailsSubmit = (formData: any) => {
    setStorage(formData);
    var data: any[] = [];
    Object.entries(formData).forEach((entry) => {
      data.push({ title: entry[0], value: entry[1] });
    });

    data = _prepareLogData(data);
    log("Step: Select Company Details", data, null);

    var signupPath = "/signup"
    //preload signup with email.
    var search = '';
    if (email) {
      search = 'email=' + encodeURIComponent(email)
    }

    navigate(`${signupPath}?${search}`, { state: { from: 'onboarding', to: 'onboarding', fromStep: activeStep, toStep: activeStep + 1 } });
    return false;
  };

  const onStepEmailClick = (event: any) => {
    var data = [{ title: "email", value: event.email }];
    data = _prepareLogData(data);
    log("Step: Email Collection", data, null);
    setEmail(event.email);
  };

  const stepUserType = (
    <StepUserType
      activeStep={activeStep}
      clinic={clinic}
      dev={dev}
      onStepUserTypeClick={onStepUserTypeClick}
    />
  );

  const stepCompanyDetails = (
    <StepCompanyDetails
      onChange={onChange}
      handleSubmit={handleSubmit(onStepCompanyDetailsSubmit)}
      activeStep={activeStep}
      register={register}
      setActiveStep={setActiveStep}
    />
  );

  const stepEmail = (
    <StepEmail
      onChange={onChange}
      handleSubmit={handleSubmit}
      activeStep={activeStep}
      onStepEmailClick={onStepEmailClick}
      register={register}
    />
  );

  const fullPathOnboarding = (
    <>
      {activeStep === 1 && stepUserType}
      {activeStep === 2 && stepEmail}
      {activeStep === 3 && stepCompanyDetails}
      {/* Then Create Account */}
    </>
  );

  const emailPathOnboarding = (
    <>
      {activeStep === 1 && stepUserType}
      {/* skip email creation */}
      {activeStep === 2 && stepCompanyDetails}
      {/* Then Create Account */}
    </>
  );

  const onBoarding = (
    <>
      {emailPassedIn ? emailPathOnboarding : fullPathOnboarding}
    </>
  );

  return (
    <div className="main-content">
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-5">
            {onBoarding}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
