import { useAuthenticator } from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";

const Billing = () => {
  const { user } = useAuthenticator((context) => [context.route]);
  const [stripeUrl, setStripeUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const billingDashboardUrl = `${process.env.REACT_APP_DASHBOARD_API}/billing`;

  const fetchDashboard = async (token: string) => {
    setLoading(true);
    await fetch(billingDashboardUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then((res) => {
        if (!res.ok && res.status === 404) {
          setError(true);
        }
        return res.json();
      })
      .then((result) => {
        setLoading(false);
        setStripeUrl(result.stripe_url)
      }).catch((error) => {
        setLoading(false);
        if (error.status === 404) {
          setError(true);
        } else {
          console.error("An error occurred while fetching the dashboard:", error);
          setError(true);
        }
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const token =  user.getSignInUserSession()?.getIdToken().getJwtToken();
    
    if (!token) {
      return;
    }
    fetchDashboard(token);
  },[]);

  if (loading) {
    return <Button variant="primary" disabled>Loading...</Button>;
  }

  if (error) {
    return <Alert variant="warning">Please contact <a href="mailto:support@cardscan.ai">support@cardscan.ai</a> to setup your account.</Alert>;
  }


  return (
    <>
      <Row>
        <h3 className="font-weight-bold">Billing Dashboard</h3>
        {stripeUrl && <>
          <Button variant="primary" href={stripeUrl} style={{width: '150px'}} target="_blank">Open Dashboard</Button>
          <small className="text-muted">Cardscan.ai's Billing Dashboard is hosted by Stripe</small>
          </>
        }
      </Row>
    </>
  );
};

export default Billing;
