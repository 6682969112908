import { FC, useRef, useState } from "react";
import { Overlay, Form } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import FeatherIcon from "feather-icons-react";

const Filters: FC<any> = ({
  setDateRangeFilter,
  setDateFromFilter,
  setDateToFilter,
  dateRangeValue,
  dateFromValue,
  dateToValue,
  setGlobalFilter,
}) => {
  const target = useRef(null);

  const [show, setShow] = useState(false);

  return (
    <div className="dropdown">
      <button
        className={`btn btn-sm btn-${show ? "primary" : "white"}`}
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-haspopup="true"
        aria-expanded="false"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <i className="fe fe-sliders me-1"></i> Filter{" "}
        <span className="badge bg-primary ms-1 d-none">0</span>
      </button>
      <Overlay
        rootClose
        target={target.current}
        show={show}
        placement="bottom-end"
      >
        {({ arrowProps: _, show, ...props }) => (
          <form
            className="dropdown-menu dropdown-menu-end dropdown-menu-card"
            {...props}
          >
            <div className="card-header">
              <h4 className="card-header-title">Filters</h4>
              <button
                className="btn btn-primary btn-sm"
                onClick={(event) => {
                  event.preventDefault();
                  setDateRangeFilter("");
                  setDateFromFilter("");
                  setDateToFilter("");
                }}
              >
                Clear filters
              </button>
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => setShow(false)}
              >
                <FeatherIcon icon="x-circle" size="1em" />
              </button>
            </div>
            <div className="card-body">
              <div className="list-group list-group-flush mb-4">
                <div className="list-group-item">
                  <div className="row align-items-center px-2">
                    <div className="col">
                      <small>Created in:</small>
                    </div>
                    <div className="col-auto">
                      <Form.Select
                        className="form-select form-select-sm"
                        name="item-title"
                        placeholder="Select Card date"
                        onChange={(event) => {
                          setDateRangeFilter(event.target.value);
                          setDateFromFilter("");
                          setDateToFilter("");
                          setGlobalFilter("");
                        }}
                        value={dateRangeValue}
                      >
                        <option value="undefined">---</option>
                        <option value="24hours">Last 24 hours</option>
                        <option value="7days">Last 7 days</option>
                        <option value="30days">Last 30 days</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="row align-items-center px-2">
                    <div className="col">
                      <small>From:</small>
                    </div>
                    <div className="col-auto">
                      <Form.Control
                        as={Flatpickr}
                        placeholder="Select Card date"
                        onChange={(value: any) => {
                          setDateRangeFilter("");
                          setDateFromFilter(value);
                          setGlobalFilter("");
                        }}
                        value={dateFromValue}
                        options={{
                          disable: [
                            function (date: any) {
                              if (!dateToValue) return false;
                              return (
                                date.getTime() >=
                                new Date(dateToValue).getTime()
                              );
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="list-group-item">
                  <div className="row align-items-center px-2">
                    <div className="col">
                      <small>To:</small>
                    </div>
                    <div className="col-auto">
                      <Form.Control
                        as={Flatpickr}
                        placeholder="Select Card date"
                        onChange={(value: any) => {
                          setDateRangeFilter("");
                          setDateToFilter(value);
                          setGlobalFilter("");
                        }}
                        options={{
                          disable: [
                            function (date: any) {
                              if (!dateFromValue) return false;
                              return (
                                date.getTime() <=
                                new Date(dateFromValue).getTime()
                              );
                            },
                          ],
                        }}
                        value={dateToValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Overlay>
    </div>
  );
};

export default Filters;
