import { Col, Row } from "react-bootstrap";
// import WidgetsPricingBasic from "../../../widgets/WidgetsPricingBasic";
import emailImage from "../../../assets/illustrations/email.svg";


const StepClinicPlan = ({
  annualBilling,
  setAnnualBilling,
}: any) => {
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8} xl={6} className="text-center">
          <h6 className="mb-4 text-uppercase text-muted">Step 4 of 4</h6>
          <h1 className="mb-3">CardScan Clinic</h1>
          <h2 className="mb-3">Thank You for Your Interest!</h2>
          <p className="mb-5 text-muted">
            This product is pre-release. You have been added to our waiting list.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8} xl={6} className="text-center">
          <img
            src={emailImage}
            alt="Thank you for your interest"
            className="mx-auto d-block"
            style={{ maxWidth: '50%', paddingTop: '1rem' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default StepClinicPlan;




// import { Col, Row } from "react-bootstrap";
// import WidgetsPricingBasic from "../../../widgets/WidgetsPricingBasic";

// const StepClinicPlan = ({
//   annualBilling,
//   setAnnualBilling,
// }: any) => {
//   return (
//     <>
//       <Row className="justify-content-center">
//         <Col xs={12} md={10} lg={8} xl={6} className="text-center">
//           <h6 className="mb-4 text-uppercase text-muted">
//             Step 4 of 4
//           </h6>
//           <h1 className="mb-3">Select a Plan</h1>
//           <p className="mb-5 text-muted">
//             All Plans include a 14-day free trial
//           </p>
//           <form className="d-flex align-items-center justify-content-center mb-3 mb-md-5">
//             <span className="text-muted">Annual</span>
//             <div className="form-check form-switch mx-3">
//               <input
//                 className="form-check-input"
//                 type="checkbox"
//                 id="billingSwitch"
//                 value={annualBilling as any}
//                 onClick={() => {
//                   setAnnualBilling(!annualBilling);
//                 }}
//               />
//             </div>

//             <span className="text-muted">Monthly</span>
//           </form>
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={12} md={6}>
//           <WidgetsPricingBasic
//             devices="2"
//             planName="Solo"
//             schedule={annualBilling ? "annual" : "monthly"}
//             price={annualBilling ? 12 : 15}
//           />
//         </Col>
//         <Col xs={12} md={6}>
//           <WidgetsPricingBasic
//             devices="7"
//             planName="Team"
//             schedule={annualBilling ? "annual" : "monthly"}
//             price={annualBilling ? 49 : 75}
//           />
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default StepClinicPlan;
