import { useRef } from "react";
import { Button, Form, FloatingLabel, Col, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StepEmail = ({
  activeStep,
  handleSubmit,
  onChange,
  onStepEmailClick,
  register,
}: any) => {
  const stepTwoForm = useRef(null);

  const navigate = useNavigate();

  return (
    <Form
      ref={stepTwoForm}
      onChange={onChange}
      onSubmit={handleSubmit((data: any) => {
        onStepEmailClick(data);
        //don't need to push search because not set if we get here.
        navigate('/onboarding', { state: { 'fromStep': activeStep, 'toStep': activeStep + 1 } });
      })}
    >
      <div>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8} xl={6} className="text-center">
            <h6 className="mb-4 text-uppercase text-muted">
              Step {activeStep} of 4
            </h6>
            <h1 className="mb-3">Enter email</h1>
            <p className="mb-5 text-muted">
              We'll send you a verification email to get started.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="form-group">
              <FloatingLabel controlId="floatingInput" label="Work Email">
                <Form.Control
                  type="email"
                  placeholder="example@hotmail.com"
                  autoComplete="email"
                  required
                  {...register("email", { required: true, maxLength: 80 })}
                />
              </FloatingLabel>
            </div>
          </Col>
        </Row>
        <Nav className="row align-items-center">
          <Col xs="auto">
            <Button
              variant="white"
              size="lg"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="text-center">
            <h6 className="text-uppercase text-muted mb-0">
              Step {activeStep} of 4
            </h6>
          </Col>
          <Col xs="auto">
            <Button type="submit" size="lg">
              Continue
            </Button>
          </Col>
        </Nav>
      </div>
    </Form>
  );
};

export default StepEmail;
