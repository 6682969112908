import "./banner.css";

const Banner = () => (
  <div className="card-scan--banner">
    <h1>Seeing is believing</h1>
    <p>
    Our drop-in React component uses your computer or phone camera to scan cards directly in the browser.
    </p>
  </div>
);

export default Banner;
