import { useRef } from "react";
import { Button, Col, Nav, Row, Form, FloatingLabel, } from "react-bootstrap";
import { useNavigate} from "react-router-dom";

const StepCompanyDetails = ({ onChange, handleSubmit, activeStep, register}: any) => {
    const stepCompanyDetailsForm = useRef(null);
    const navigate = useNavigate();

  return (
    <Form
      ref={stepCompanyDetailsForm}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8} xl={6} className="text-center">
          <h6 className="mb-4 text-uppercase text-muted">
            Step {activeStep} of 4
          </h6>
          <h1 className="mb-3">Company Profile</h1>
          <p className="mb-5 text-muted">
            These details will help us configure your account.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="form-group">
            <FloatingLabel
              controlId="floatingInput"
              label="First name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Jane"
                autoComplete="given-name"
                required
                {...register("firstName", { required: true, maxLength: 80 })}
              />
            </FloatingLabel>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="form-group">
            <FloatingLabel controlId="floatingInput" label="Last name">
              <Form.Control
                type="text"
                placeholder="Doe"
                autoComplete="family-name"
                required
                {...register("familyName", { required: true, maxLength: 80 })}
              />
            </FloatingLabel>
          </div>
        </Col>
      </Row>
      <div className="form-group">
        <FloatingLabel controlId="floatingInput" label="Company name">
          <Form.Control
            type="text"
            placeholder="Company name"
            autoComplete="organization"
            required
            {...register("organization", { required: true, maxLength: 80 })}
          />
        </FloatingLabel>
      </div>
      <div className="form-group">
        <FloatingLabel controlId="floatingInput" label="Website">
          <Form.Control
            type="text"
            placeholder="example.com"
            autoComplete="organization"
            {...register("website", { maxLength: 80 })}
          />
        </FloatingLabel>
      </div>
      <div className="form-group mb-6">
        <Form.Label>Expected Monthly Usage</Form.Label>
        <Form.Select required {...register("usage", { required: true})}>
          <option disabled value="">
            Unique cards scanned per month
          </option>
          <option value="Less than 100">Less than 100</option>
          <option value="100-500">100-500</option>
          <option value="501-2000">501-2000</option>
          <option value="2001-5000">2001-5000</option>
          <option value="5000+">5000+</option>
        </Form.Select>
      </div>
      <Nav className="row align-items-center">
        <Col xs="auto">
          <Button
            variant="white"
            size="lg"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Col>
        <Col className="text-center">
          <h6 className="text-uppercase text-muted mb-0">
            Step {activeStep} of 4
          </h6>
        </Col>
        <Col xs="auto">
          <Button type="submit" size="lg">
            Continue
          </Button>
        </Col>
      </Nav>
    </Form>
  );
};

export default StepCompanyDetails;
