import { Text, Flex, useTheme } from "@aws-amplify/ui-react";

export default function AuthFooter() {
  const { tokens } = useTheme();
  const year = new Date().getFullYear();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; {year} CardScan.ai</Text>
    </Flex>
  );
}