import { FC, ReactNode } from "react";
import SideNav from "../../components/SideNav/Sidenav";

interface Props {
  children?: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div>
      <SideNav />
      <div
        className="main-content"
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;