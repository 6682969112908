import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function AccountChangeYourPassword() {
  const { user } = useAuthenticator((context) => [context.route]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({});

  const handlePasswordUpdate = async (formData: any) => {
    setError(null);
    try {
      setIsLoading(true);
      await Auth.changePassword(user, formData.password, formData.new_password);
      setIsLoading(false);
      reset({ password: "", new_password: "", confirm_password: "" });
      toast.success('Password has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
      });
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row className="justify-content-between align-items-center mb-5">
        <Col xs={12} md={9} xl={7}>
          <h2 className="mb-2">Change your password</h2>
          <p className="text-muted mb-xl-0">
            You will recieve an email confirmation after changing your password.
          </p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xs={12} md={6} className="order-md-2">
          <Card className="bg-light border ml-md-4">
            <Card.Body>
              <p className="text-start mb-2">Password requirements</p>
              <p className="text-start text-muted mb-2">
                To create a new password, you have to meet all of the following
                requirements:
              </p>
              <ul className="text-start small text-muted ps-4 mb-0">
                <li>Minimum 8 character</li>
                <li>At least one special character</li>
                <li>At least one number</li>
                <li>Can't be the same as a previous password</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col-12 col-md-6">
          {error ? <Alert variant={"danger"}>{error}</Alert> : ""}
          <form onSubmit={handleSubmit(handlePasswordUpdate)}>
            <div className="form-group">
              <Form.Label>Current password</Form.Label>
              <Form.Control
                type="password"
                {...register("password", { required: true })}
              />
              <p className="text-danger">
                {errors.password ? "Password is Required" : ""}
              </p>
            </div>
            <div className="form-group">
              <Form.Label>New password</Form.Label>
              <Form.Control
                type="password"
                {...register("new_password", { required: true })}
              />
              <p className="text-danger">
                {errors.new_password ? "New Password is Required" : ""}
              </p>
            </div>
            <div className="form-group">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                type="password"
                {...register("confirm_password", {
                  required: true,
                  validate: (val: string) => {
                    if (watch("new_password") !== val) {
                      return "Your passwords do no match";
                    }
                  }
                })}
              />
              <p className="text-danger">
                <>
                  {errors.confirm_password &&
                    errors.confirm_password?.type === "validate"
                    ? errors.confirm_password?.message
                    : ""}
                  {errors.confirm_password &&
                    errors.confirm_password?.type === "required"
                    ? "Confirm Password is Required"
                    : ""}
                </>
              </p>
            </div>
            <Button disabled={isLoading} type="submit" className="lift w-100">
              Update password
            </Button>
          </form>
        </Col>
      </Row>
    </>
  );
}
