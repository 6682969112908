import { Button, Col, Nav, Row, Form } from "react-bootstrap";
import { useState} from 'react';

import { Select } from "../../vendor";

const StepAPIUsage = ({
  onStepAPIUsageClick,
  setUseCaseOptions,
  useCaseOptions,
  integrationOptions,
  setIntegrationOptions,
  timelineOptions,
  setTimelineOptions,
}) => {
  const [loading, setLoading ] = useState(false);

  const processClick = (event) => {
    setLoading(true);
    return onStepAPIUsageClick(event);
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8} xl={6} className="text-center">
          <h6 className="mb-4 text-uppercase text-muted">
          </h6>
          <h1 className="mb-3">Final Step</h1>
          <p className="mb-5 text-muted">
            Sandbox Account - Implementation Questions
          </p>
        </Col>
      </Row>
      <div className="form-group">
        <Form.Label>Use Cases*</Form.Label>
        <Select
          value={useCaseOptions}
          onChange={setUseCaseOptions}
          name="usecase"
          required
          options={[
            {
              value: "Patient Onboarding",
              label: "Patient Onboarding",
            },
            {
              value: "Patient Tools",
              label: "Patient Tools",
            },
            {
              value: "Clinician Tools",
              label: "Clinician Tools (EMR, workflow, billing, etc.)",
            },
            {
              value: "Internal Tools",
              label: "Internal Tools",
            },
            {
              value: "Other",
              label: "Other",
            },
          ]}
          placeholder="What use cases are you attempting to solve"
          size="lg"
          isMulti
        />
      </div>
      <div className="form-group">
        <Form.Label>Integrations*</Form.Label>
        <Select
          value={integrationOptions}
          onChange={setIntegrationOptions}
          required
          options={[
            {
              value: "API",
              label: "CardScan API",
            },
            {
              value: "react",
              label: "React Widget",
            },
            {
              value: "swift",
              label: "iOS Widget",
            },
            {
              value: "kotlin",
              label: "Android Widget",
            },
            {
              value: "react-native",
              label: "React Native Widget",
            },
            {
              value: "flutter",
              label: "Flutter Widget",
            },
            {
              value: "js",
              label: "Vanilla JS",
            },
            {
              value: "other",
              label: "Other",
            },
          ]}
          placeholder="Pick the technologies you plan on using"
          size="lg"
          isMulti
        />
      </div>
      <Row>
        <Col xs={12} md={6}>
          <div className="form-group">
            <Form.Label>Integration Timeline*</Form.Label>
            <Select
              value={timelineOptions}
              onChange={setTimelineOptions}
              required
              options={[
                {
                  value: "2022 Q3",
                  label: "2022 - Q3",
                },
                {
                  value: "2022 Q4",
                  label: "2022 - Q4",
                },
                {
                  value: "2023 Q1",
                  label: "2023 - Q1",
                },
                {
                  value: "2023 Q2",
                  label: "2023 - Q2",
                },
                {
                  value: "2023 Q3",
                  label: "2023 - Q3",
                },
                {
                  value: "Unknown",
                  label: "Unknown",
                },
              ]}
              placeholder="Rough timeline for deployment"
              size="lg"
            />
          </div>
        </Col>
      </Row>
      <hr className="my-5" />
      <Nav className="row align-items-center">
    
        <Col xs="auto">
          <Button
            onClick={processClick}
            disabled={ 
              loading || !timelineOptions || timelineOptions.length < 1 || !integrationOptions || integrationOptions.length < 1 || !useCaseOptions || useCaseOptions.length < 1
            }
            type="submit"
            size="lg"
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
          >
            {loading ? <span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving</span> : 'Save'}
          </Button>
        </Col>
      </Nav>
    </>
  );
};

export default StepAPIUsage;
