export const log = (message, fields, success) => {
  const url = `${process.env.REACT_APP_DASHBOARD_API}/log`
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json' },
    body: JSON.stringify({ 'message': message, 'fields': fields})
  };

  fetch(url, requestOptions)
      .then(res => res.json())
      .then(data =>  {if (success) success(data)})
      .catch(error => {console.error(error)})
}

export const ulog = (user, message, fields, success) => {
  const token =  user.getSignInUserSession()?.getIdToken().getJwtToken();
  const url = `${process.env.REACT_APP_DASHBOARD_API}/ulog`
  const requestOptions = {
    method: 'POST',
    headers: { 
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json' },
    body: JSON.stringify({ 'message': message, 'fields': fields})
  };

  fetch(url, requestOptions)
      .then(res => res.json())
      .then(data =>  {if (success) success(data)})
      .catch(error => {console.error(error)})
}

export default null;