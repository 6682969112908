// import { parse } from "json2csv";

export const exportToCSV = (jsonData: any) => {
  const fields = [
    "card_id",
    "created_at",
    "state",
    "card_specific_id",
    "dependent_names",
    "group_number",
    "member_name",
    "member_number",
    "payer_name",
    "plan_id",
    "rx_bin",
    "rx_pcn",
  ];
  const opts = { fields, includeEmptyRows: true };
  const mapData = (card: any) => ({
    card_id: card.card_id,
    created_at: card.created_at,
    state: card.state,
    card_specific_id: card?.details?.card_specific_id?.value || "",
    dependent_names: card?.details?.dependent_names?.value || "",
    group_number: card?.details?.group_number?.value || "",
    member_name: card?.details?.member_name?.value || "",
    member_number: card?.details?.member_number?.value || "",
    payer_name: card?.details?.payer_name?.value || "",
    plan_id: card?.details?.plan_id?.value || "",
    rx_bin: card?.details?.rx_bin?.value || "",
    rx_pcn: card?.details?.rx_pcn?.value || "",
  });
  try {
    const csv = ''// parse(jsonData.map(mapData), opts);
    const dataUri = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

    const exportFileDefaultName = "cards.csv";

    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  } catch (err) {
    console.error(err);
  }
};

export const exportToJSON = (jsonData: any) => {
  const dataStr = JSON.stringify(jsonData);
  const dataUri =
    "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

  const exportFileDefaultName = "cards.json";

  const linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", exportFileDefaultName);
  linkElement.click();
};

export const perPageOptions = [10, 30, 50, 100];