import { Flex, Image, useTheme , Grid, View } from "@aws-amplify/ui-react";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';
import SignInHeader from './SignInHeader';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from "react";

// Define a type for the state
interface LocationState {
  from?: {
    pathname: string;
  };
}

const SignIn = () => {

  const { tokens } = useTheme();

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || '/';
  
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  
  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <Flex
        backgroundColor={tokens.colors.background.secondary}
        justifyContent="center"
      >
      <Authenticator 
        loginMechanisms={['email']}
        initialState="signIn"
        components={{
          Header: AuthHeader,
          SignIn: {
            Header: SignInHeader,
          },
          Footer: AuthFooter
        }}
      >
      </Authenticator>
      </Flex>
      <View height="100vh">
        <Image
          className="auth-image"
          alt=''
          src="https://www.cardscan.ai/assets/img/covers/main-top.jpg"
          height="100%"
          objectFit="cover"          
        />
      </View>
    </Grid>
  );
}

export default SignIn;