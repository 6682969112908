import { useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import emailImage from "../../assets/illustrations/email.svg"
import { ulog } from "../../widgets/DashboardLog"
import { useLocation } from "react-router-dom";

const finalStep = (
  <>
    <Row className="justify-content-center">
      <Col xs={12} md={10} lg={8} xl={6} className="text-center">
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12}>
        <Card className="mt-7">
          <Card.Header >
              <h2 className="card-header-title">Account Created</h2>
          </Card.Header>
          <Card.Img className='mx-auto d-block' style={{maxWidth: '40%', paddingTop: '1rem'}}  variant="top" src={emailImage} alt="Please Check Your Email" />
          <Card.Body>
            <Row className="align-items-center">
              <Col>
                <h3 className="mb-1" style={{padding: '1rem 20%'}}>Your account is being provisioned, you will receive an email once it is ready. <p/>This may take up to 1 hour.</h3>
                <small>No email? Contact</small><a href='mailto:team@cardscan.ai'><small> team@cardscan.ai</small></a>.
              </Col>
            </Row>
          </Card.Body>
        </Card>          
      </Col>
    </Row>
  </>  
);

const StripeLanding = () => {
  const { user } = useAuthenticator((context) => [context.route]);
  let location = useLocation();

  useEffect(() => { 
    if (!user) return;
    var search = new URLSearchParams(location.search);
    var data : any = [];
    data.push({'title': 'success', 'value': search.get('success')})
    data.push({'title': 'session_id', 'value': search.get('session_id')})
    data.push({'title': 'canceled', 'value': search.get('canceled')})
    ulog(user, "Stripe", data, null)
  }, [user]);

  return (
    <div className="main-content">
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-5">
            {finalStep}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StripeLanding;