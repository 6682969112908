import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import useLocalState from "./useLocalState";
import { Col, Container, Row } from "react-bootstrap";
import { ulog } from "../../widgets/DashboardLog";

import StepAPIUsage from "./steps/StepAPIUsage";
import StepClinicPlan from "./steps/StepClinicPlan";
import FinalStep from "./steps/FinalStep";
import { useAuthenticator } from "@aws-amplify/ui-react";

const clinic = "CardScan Clinic";
const dev = "CardScan APIs";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function AccountSetup() {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.route]);

  const params = useQuery();

  const [loaded, setLoaded] = useState(false);
  const [storage, setStorage] = useLocalState("formValues", {});

  const [selection, setSelection] = useLocalState<string | null>(
    "selection",
    null
  );
  const [annualBilling, setAnnualBilling] = useLocalState(
    "annualbilling",
    true
  );
  const { reset, watch, getValues } = useForm();

  const formData = watch();

  let location = useLocation();


  useEffect(() => {

    if (!user) {
      return;
    }

    var data = _prepareLogData([{ 'title': 'selection', 'value': selection }])
    ulog(user, "Step: Account Setup Start", data, null);

  }, [user]);

  useEffect(() => {
    if (!selection) {
      navigate('/onboarding');
    }
  }, []);

  useEffect(() => {

    if (!loaded) {
      reset(storage);
      setLoaded(true);
    }
  }, [storage, reset, loaded]);

  const _prepareLogData = (data: any) => {

    data.push({ 'title': 'search', 'value': location.search });
    return data;
  }

  const [useCaseOptions, setUseCaseOptions] = useLocalState("usecase", []);
  const [integrationOptions, setIntegrationOptions] = useLocalState(
    "integration",
    []
  );
  const [timelineOptions, setTimelineOptions] = useLocalState("timeline", null);

  const onStepAPIUsageClick = (event: any) => {
    event.preventDefault();

    var _data = [
      {
        title: "useCase",
        value: useCaseOptions.map((e: any) => e.value).join(", "),
      },
      {
        title: "integrations",
        value: integrationOptions.map((e: any) => e.value).join(", "),
      },
      { title: "timeline", value: (timelineOptions as any).value },
    ];

    var data = _prepareLogData(_data);
    ulog(user, "Step: API Usage", data, null);

    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    const accountDetailsUrl = `${process.env.REACT_APP_DASHBOARD_API}/account`;

    const useCases = data.find((x: { title: string; }) => x.title === "useCase");
    const integrations = data.find((x: { title: string; }) => x.title === "integrations");
    const timeline = data.find((x: { title: string; }) => x.title === "timeline");

    const { email, familyName, firstName, organization, usage, website } = getValues();

    let userData = {
      family_name: familyName,
      given_name: firstName,
    };

    fetch(accountDetailsUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account_type: 'Developers',
        account_usecases: useCases,
        account_integrations: integrations,
        account_timeline: timeline,
        account_firstName: firstName,
        account_familyName: familyName,
        account_url: website,
        account_companyname: organization,
        account_usage: usage,
        account_email: email,
      }),
    }).then((r) => {
      return Auth.currentUserPoolUser({ bypassCache: true });
    })
      .then(async (_user) => {
        var data = _prepareLogData([]);
        ulog(_user, "API Onboarding Complete", data, null);

        try {
          await Auth.updateUserAttributes(user, {
            ...userData
          });
        } catch (error) {
          console.error(error);
        }

        navigate("/");
      }).catch((error) => {
        console.error(error);
      });

  };

  const isDeveloper = (user: any) => {
    if (!user) {
      return false;
    }
    const payload = user.getSignInUserSession()?.getIdToken().getJwtToken().payload;
    if (!payload) {
      return false;
    }
    const groups = ('cognito:groups' in payload ? payload['cognito:groups'] : []);
    return groups.includes("Developers");
  }

  const stepAPIUsage = (
    <StepAPIUsage
      onStepAPIUsageClick={onStepAPIUsageClick}
      setUseCaseOptions={setUseCaseOptions}
      useCaseOptions={useCaseOptions}
      integrationOptions={integrationOptions}
      setIntegrationOptions={setIntegrationOptions}
      timelineOptions={timelineOptions}
      setTimelineOptions={setTimelineOptions}
    />
  );

  const stepClinicPlan = (
    <StepClinicPlan
      annualBilling={annualBilling}
      setAnnualBilling={setAnnualBilling}
    />
  );

  const finalStep = <FinalStep />;

  const onboarding = (
    <>
      {selection === clinic ? stepClinicPlan : ""}
      {selection === dev ? stepAPIUsage : ""}
    </>
  );

  return (
    <div className="main-content">
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8} className="py-5">
            {isDeveloper(user) ? finalStep : onboarding}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
