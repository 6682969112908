import AceEditor from "react-ace";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/webpack-resolver";

import "./results.css";

import Card, { FieldValue }  from "../../model/Card/Card";
import TableResults from "../ResultsTable";

import "react-tabs/style/react-tabs.css";

const getDependentNames = (dependentNames : FieldValue[]) => dependentNames.reduce((acc, dependent) => `${dependent.value} ${acc}`, '')


const Results = ({
  cardImage,
  cardDetails,
  handleClick,
}: {
  cardImage: string;
  cardDetails: Card;
  handleClick: () => void;
}) => (
  <div style={{ display: "flex", flexDirection: "column", marginTop: "100px", padding: '24px' }} className="results-page">
    <div 
      style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}
    >
      <div>
        <h2>Card Scanning Results</h2> 
        <h4>
          Scanned images are uploaded, processed, and a JSON payload is returned.
        </h4>
      </div>
      <button onClick={handleClick} className="result-button">
        Try Again
      </button>
    </div>
    <div
      style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
    >
      <img
        src={cardImage}
        alt="Uploaded insurance card"
        style={{ marginRight: "50px", alignSelf: "flex-start", marginTop: "24px", maxWidth: "450px" }}
      />
      <Tabs style={{ width: "600px", marginTop: "24px" }}>
        <TabList>
          <Tab>Table</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanel>
          <TableResults
            payerName={cardDetails.details?.payerName?.value || ''}
            planName={cardDetails.details?.planName?.value || ''}
            memberNumber={cardDetails.details?.memberNumber?.value || ''}
            groupNumber={cardDetails.details?.groupNumber?.value || ''}
            member={cardDetails.details?.memberName?.value || ''}
            dependents={getDependentNames(cardDetails.details?.dependentNames || [])}
            rxbin={cardDetails.details?.rxBin?.value || ''}
            rxPCN={cardDetails.details?.rxPCN?.value || ''}
          />
        </TabPanel>
        <TabPanel>
          <AceEditor
            mode="json"
            theme="merbivore_soft"
            readOnly={true}
            name="json-ace-view"
            value={JSON.stringify(cardDetails, null, 2)}
            showGutter={false}
            fontSize={15}
            width="100%"
          />
        </TabPanel>
      </Tabs>
    </div>
  </div>
);

export default Results;
