import React, { Dispatch, SetStateAction } from "react";
import { CardScanApi } from "@cardscan.ai/insurance-cardscan-react";

type CardScanApiT = CardScanApi | null;

const CardScanApiContext = React.createContext<{
  cardScanApi: CardScanApiT;
  setCardScanApi: Dispatch<SetStateAction<CardScanApiT>>;
}>({
  cardScanApi: null,
  setCardScanApi: () => {},
});

export default CardScanApiContext;
