import { FC } from "react";
import {
  Card,
  Dropdown,
  DropdownButton,
  Form,
  NavDropdown,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

import Filters from "./Filters";
import { perPageOptions } from "./helpers";

const Controls: FC<any> = ({
  handleUpdateRangeValue,
  setDateFromFilter,
  setDateToFilter,
  dateRangeValue,
  dateFromValue,
  dateToValue,
  handlePerPageClick,
  perPage,
  handleExport,
  setPageSize,
  setGlobalFilter,
  ...rest
}) => {
  return (
    <Row className="align-items-center mb-4">
      <Col xs="auto">
        <Card className="checkbox__card">
          <Card.Header>
            <IndeterminateCheckbox type="checkbox" {...rest} />
          </Card.Header>
        </Card>
      </Col>
      <Col>
        <InputGroup size="lg" className="input-group-merge input-group-reverse">
          <Form.Control
            type="search"
            placeholder="Search"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <InputGroup.Text>
            <FeatherIcon icon="search" size="1em" />
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Col xs="auto" className="ms-n3">
        <NavDropdown
          id="nav-dropdown-dark-example"
          title={`${perPage} per page`}
          menuVariant="dark"
          className="card__per-page"
        >
          {perPageOptions.map((option) => (
            <NavDropdown.Item
              key={option}
              onClick={() => {
                setPageSize(option);
                handlePerPageClick(option);
              }}
            >
              {option} per page
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </Col>
      <Col xs="auto">
        <Filters
          setDateRangeFilter={handleUpdateRangeValue}
          setDateFromFilter={setDateFromFilter}
          setDateToFilter={setDateToFilter}
          dateRangeValue={dateRangeValue}
          dateFromValue={dateFromValue}
          dateToValue={dateToValue}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>
      <Col xs="auto">
        <DropdownButton id="dropdown-basic-button" title="Export">
          <Dropdown.Item onClick={() => handleExport("csv")}>CSV</Dropdown.Item>
          <Dropdown.Item onClick={() => handleExport("json")}>
            JSON
          </Dropdown.Item>
        </DropdownButton>
      </Col>
    </Row>
  );
};

export default Controls;
