import { Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import Developer from "../../../assets/illustrations/developers.svg";

const FinalStep = () => (
  <>
    <Row className="justify-content-center">
      <Col xs={12} md={10} lg={8} xl={6} className="text-center">
        <h1 className="mb-6">Onboarding Complete</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12}>
        <Card>
          <Card.Header>
            <h2 className="card-header-title">Account Provisioning</h2>
          </Card.Header>
          <Card.Img
            className="mx-auto d-block"
            style={{ maxWidth: "40%", paddingTop: "1rem" }}
            variant="top"
            src={Developer}
            alt="Account Complete"
          />
          <Card.Body>
            <Row className="align-items-center">
              <Col>
                <h3 className="mb-1" style={{ padding: "1rem 20%" }}>
                  Account has been successfully provisioned! <p />
                  Your API Keys are available <Link to="/keys"><u>here</u></Link>.
                </h3>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </>
);

export default FinalStep;
