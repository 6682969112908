const currentConfig = {
    aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_mandatory_sign_in: 'enable',

    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
          'REQUIRES_LOWERCASE',
          'REQUIRES_NUMBERS',
          'REQUIRES_UPPERCASE',
        ],
    },
};

export const cognitoConfig = {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,

    cookieStorage: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        path: '/',
        expires: 365,
        sameSite: (process.env.REACT_APP_AUTH_DOMAIN === "localhost" ? "lax" : "strict"),
        secure: (process.env.REACT_APP_AUTH_DOMAIN === "localhost" ? false : true)
    }
}


export default currentConfig;