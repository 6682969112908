export default {
  base: {
    children: ["demo", "onboarding", "account"],
    id: "base",
  },
  demo: {
    icon: "camera",
    id: "demo",
    title: "Demo",
    url: "/demo",
  },
  onboarding: {
    icon: "book-open",
    id: "onboarding",
    title: "Onboarding",
    url: "/setup",
  },
  account: {
    icon: "user",
    id: "account",
    title: "Account",
    url: "/account/general",
  },
};
