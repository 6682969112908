import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AmplifyUser } from "@aws-amplify/ui";

export interface UserAccount {
  account_name: string;
  account_id: string;
  account_slug: string;
  account_url: string;
  account_type: string;
  [otherProps: string]: any;
}

const General = () => {
  const { user } = useAuthenticator((context) => [context.route]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    // reValidateMode: "onChange"
    // defaultValues: {}
  });

  const accountDetailsUrl = `${process.env.REACT_APP_DASHBOARD_API}/account`;

  const fetchAccount = async (user: AmplifyUser) => {
    if (!user) return;
    const token =  user.getSignInUserSession()?.getIdToken().getJwtToken();

    await fetch(accountDetailsUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (!result.account_url) {
          setValue("account_url", "");
        }
        setValue("family_name", user.attributes?.family_name);
        setValue("given_name", user.attributes?.given_name);
        setValue("phone_number", user.attributes?.phone_number);
        setValue("email", user.attributes?.email);
        Object.keys(result).map((key) => setValue(key, result[key]));
      });
  };

  useEffect(() => {
    fetchAccount(user);
  },[user]);

  const handleAccountUpdate = async (formData: any) => {
    const token =  user.getSignInUserSession()?.getIdToken().getJwtToken();
    let userData = {
      family_name: formData.family_name,
      given_name: formData.given_name,
      phone_number: formData.phone_number ? '+1' + formData.phone_number.replace(/[^\d]/g, "") : ""
    };

    let accountData = {
      account_name: formData.account_name,
      account_url: formData.account_url
    };
    try {
      setAccountLoading(true);
      await Auth.updateUserAttributes(user, {
        ...userData
      });

      let response = await fetch(accountDetailsUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(accountData)
      });
      let result = await response.json();
      setAccountLoading(false);
    } catch (e) {
      setAccountLoading(false);
      console.log(e);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await Auth.deleteUser();
      setIsLoading(false);
      window.open("/signin", "_self");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleAccountUpdate)}>
      <Row>
        <h3 className="font-weight-bold">User Details</h3>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <div className="form-group">
            <FloatingLabel label="First Name">
              <Form.Control
                type="text"
                // value={user?.attributes?.given_name}
                {...register("given_name", { required: true })}
              />
            </FloatingLabel>
            <p className="text-danger">
              {errors.given_name ? "First Name is Required" : ""}
            </p>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="form-group">
            <FloatingLabel label="Last Name">
              <Form.Control
                type="text"
                // value={user?.attributes?.family_name}
                {...register("family_name", { required: true })}
              />
            </FloatingLabel>
            <p className="text-danger">
              {errors.family_name ? "Last Name is Required" : ""}
            </p>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="form-group">
            <Form.Text className="small text-muted">&nbsp;</Form.Text>
            <FloatingLabel label="Work Email" className="mb-1">
              <Form.Control
                type="email"
                // value={user?.attributes.email}
                {...register("email", { required: true })}
                disabled
              />
            </FloatingLabel>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="form-group">
            <Form.Text className="small text-muted text-end">
              Only used for Account recovery
            </Form.Text>
            <FloatingLabel label="Mobile Phone" className="mb-1">
              <Controller
                name="phone_number"
                control={control}
                // rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <PatternFormat
                    {...field} 
                    format="(###)-###-####"
                    mask="_"
                    className="form-control"
                    onValueChange={values => field.onChange(values.value)}
                  />
                )}
              />
            </FloatingLabel>
            <p className="text-danger">
              {errors.phone_number ? "Phone is Required" : ""}
            </p>
          </div>
        </Col>
        <hr className="mt-4 mb-4"></hr>
        <h3>Company Details</h3>
        <Col xs={12} className="col-12">
          <div className="form-group">
            <FloatingLabel label="Company Name">
              <Form.Control
                type="text"
                // value={account?.account_name}
                {...register("account_name", { required: true })}
              />
            </FloatingLabel>
            <p className="text-danger">
              {errors.account_name ? "Account Name is Required" : ""}
            </p>
          </div>
        </Col>
        <Col xs={12} className="col-12">
          <div className="form-group">
            <FloatingLabel label="Company Website">
              <Form.Control
                type="text"
                // value={account?.account_url}
                {...register("account_url")}
              />
            </FloatingLabel>
          </div>
        </Col>
      </Row>
      <Button disabled={accountLoading} type="submit">
        Save changes
      </Button>
      {/* <hr className="my-5" /> */}
      <hr className="mt-4 mb-5" />
      <Row className="justify-content-between">
        <Col xs={12} md={6} className="col-12 col-md-6">
          <h4>Delete your account</h4>
          <p className="small text-muted mb-md-0">
            Please note, deleting your account is a permanent action and will
            not be recoverable once completed.
          </p>
        </Col>
        <Col xs="auto">
          <Button variant="danger" disabled={isLoading} onClick={handleDelete}>
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default General;
