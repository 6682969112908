import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactSpinKit from "react-spinkit";
import { CardScanApi } from "@cardscan.ai/insurance-cardscan-react";
import { matchSorter } from "match-sorter";
import { Alert, Button, Col, Form, Pagination, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import Card from "./Card";
import Header from "./Header";
import Controls from "./Controls";
import TableView from "./TableView";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import CardScanApiContext from "../../context/cardScanApiContext";
import APIKeyContext from "../../context/APIKeyContext";

import FallbackCard from "../../assets/images/fallback.png";

import { keywords } from "./data";

import { exportToCSV, exportToJSON } from "./helpers";
import {
  filterByDateRange,
  filterByDateFrom,
  filterByDateTo,
  filterByStatus,
  formatDate,
} from "./filterHelpers";

const Cards = () => {
  const { apiKeys } = useContext(APIKeyContext);

  const { cardScanApi, setCardScanApi } = useContext(CardScanApiContext);

  const [cardsData, setCardsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isSandbox, setIsSandbox] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [perPage, setPerPage] = useState(50);

  const [activeTab, setActiveTab] = useState<1 | 0>(1);

  const [dateRangeValue, setDateRangeFilter] = useState("");
  const [dateFromValue, setDateFromFilter] = useState("");
  const [dateToValue, setDateToFilter] = useState("");

  const handleUpdateRangeValue = (value?: string) => {
    setDateRangeFilter(value || "");
  };

  const handleExport = (type: "csv" | "json") =>
    type === "csv" ? exportToCSV(filteredData) : exportToJSON(filteredData);

  const handlePerPageClick = (option: number) => {
    setPerPage(option);
  };

  const handleSelect = (status: string | null) => {
    status === "all"
      ? setFilteredData(cardsData)
      : setFilteredData(filterByStatus(cardsData, status || ""));
  };

  const handleEnvConnection = useCallback(
    (isSandbox:any) => {
      setCardScanApi(
        new CardScanApi({
          live: !isSandbox,
          sessionToken: isSandbox ? apiKeys.sandbox : apiKeys.production,
        })
      );
    },
    [apiKeys?.sandbox, apiKeys?.production, setCardScanApi]
  );

  useEffect(() => {
    const cardImageNodes = document.getElementsByClassName("card__image");
    const cardImageList = Array.from(cardImageNodes);

    cardImageList.forEach((cardImageNode) => {
      cardImageNode.addEventListener("error", () => {
        if (cardImageNode instanceof HTMLImageElement) {
          cardImageNode.src = FallbackCard;
        }
      });
    });
  });

  useEffect(() => {
    if (apiKeys && !cardScanApi) {
      handleEnvConnection(isSandbox);
    }
  }, [setCardScanApi, apiKeys, handleEnvConnection, cardScanApi, isSandbox]);

  useEffect(() => {
    setCardsData([]);
    const fetchCardsData = async ({ cursor }: { cursor?: any }) => {
      if (cardScanApi) {
        const result = await cardScanApi.listCards({ cursor, limit: 200 });
        setCardsData((cards) => [...cards, ...result.data.cards]);
        setIsLoading(false);
        // if (result.data.response_metadata.next_cursor) {
        //   fetchCardsData({
        //     cursor: result.data.response_metadata.next_cursor,
        //   });
        // } else {
        //   setIsLoading(false);
        // }
      }
    };
    fetchCardsData({});
  }, [cardScanApi]);

  useEffect(() => {
    setFilteredData(filterByStatus(cardsData, "completed"));
  }, [cardsData]);

  const data = useMemo(() => {
    return filteredData;
  }, [filteredData]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({
          getToggleAllRowsSelectedProps,
        }: {
          getToggleAllRowsSelectedProps: any;
        }) => (
          <div>
            <IndeterminateCheckbox
              type="checkbox"
              {...getToggleAllRowsSelectedProps()}
            />
          </div>
        ),
        Cell: ({ row }: { row: any }) => (
          <div>
            <IndeterminateCheckbox
              type="checkbox"
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "details.member_name.value",
      },
      {
        Header: "Payer",
        accessor: "details.payer_name.value",
      },
      {
        Header: "Member ID",
        accessor: "details.member_number.value",
      },
      {
        Header: "Group ID",
        accessor: "details.group_number.value",
      },
      {
        Header: "Status",
        accessor: "state",
        Cell: ({ value }: { value: any }) => (
          <div className={`card__header-status card__header-status-${value}`}>
            {value}
          </div>
        ),
      },
      {
        Header: "Created",
        accessor: (originalRow: any) => {
          return formatDate(originalRow.created_at);
        },
      },
    ],
    []
  );

  const ourGlobalFilterFunction = useCallback(
    (rows: any, ids: any, query: string) => {
      if (
        (!dateRangeValue || dateRangeValue === "undefined") &&
        !dateFromValue &&
        !dateToValue &&
        !query
      )
        return rows;
      // date rage
      const filteredDataByDateRange = filterByDateRange(
        rows.map((row: any) => row.original),
        dateRangeValue
      );
      const filteredRowsByDateRange = rows.filter((row: any) =>
        filteredDataByDateRange
          .map((row: any) => row.card_id)
          .includes(row.original.card_id)
      );

      const filteredDataByDateFrom = filterByDateFrom(
        filteredRowsByDateRange.map((row: any) => row.original),
        dateFromValue
      );
      const filteredRowsByDateFrom = rows.filter((row: any) =>
        filteredDataByDateFrom
          .map((row: any) => row.card_id)
          .includes(row.original.card_id)
      );

      const filteredDataByDateTo = filterByDateTo(
        filteredRowsByDateFrom.map((row: any) => row.original),
        dateToValue
      );
      const filteredRowsByDateTo = rows.filter((row: any) =>
        filteredDataByDateTo
          .map((row: any) => row.card_id)
          .includes(row.original.card_id)
      );

      const filteredRowsByQuery = matchSorter(filteredRowsByDateTo, query, {
        keys: keywords.map((keyword) => `original.${keyword}`),
      });
      return filteredRowsByQuery;
    },
    [dateRangeValue, dateFromValue, dateToValue]
  );

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    gotoPage,
    nextPage,
    page,
    prepareRow,
    pageOptions,
    previousPage,
    headerGroups,
    setGlobalFilter,
    setPageSize,
    getToggleAllRowsSelectedProps,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = useTable<any>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 } as any,
      globalFilter: ourGlobalFilterFunction,
      autoResetSelectedRows: false,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  ) as any;

  return (
    <div className="main-content">
      {isLoading ? (
        <div className="card-scan--spinner-wrapper">
          <ReactSpinKit
            name="ball-spin-fade-loader"
            color="#335eea"
            fadeIn="quarter"
          />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <Header
              handleSelect={handleSelect}
              statusQuantities={[
                filterByStatus(cardsData, "completed").length,
                cardsData.length,
                filterByStatus(cardsData, "processing").length,
                filterByStatus(cardsData, "error").length,
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isSandbox={isSandbox}
              disabledProd={!apiKeys?.production}
              setIsSandbox={(value) => {
                setIsSandbox(value);
                handleEnvConnection(value);
              }}
            />
          </div>
          {activeTab === 0 ? (
            <div className="container-fluid">
              <TableView
                setFilter={setGlobalFilter}
                getTableBodyProps={getTableBodyProps}
                perPage={perPage}
                handlePerPageClick={handlePerPageClick}
                headerGroups={headerGroups}
                page={page}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                setPageSize={setPageSize}
                prepareRow={prepareRow}
                setGlobalFilter={setGlobalFilter}
                handleExport={handleExport}
                nextPage={nextPage}
                handleUpdateRangeValue={handleUpdateRangeValue}
                setDateFromFilter={setDateFromFilter}
                setDateToFilter={setDateToFilter}
                dateRangeValue={dateRangeValue}
                dateFromValue={dateFromValue}
                dateToValue={dateToValue}
              />
            </div>
          ) : (
            <div className="container-fluid">
              <Controls
                handleUpdateRangeValue={handleUpdateRangeValue}
                setDateFromFilter={setDateFromFilter}
                setDateToFilter={setDateToFilter}
                setPageSize={setPageSize}
                dateRangeValue={dateRangeValue}
                dateFromValue={dateFromValue}
                dateToValue={dateToValue}
                perPage={perPage}
                handleExport={handleExport}
                handlePerPageClick={handlePerPageClick}
                setGlobalFilter={setGlobalFilter}
                {...getToggleAllRowsSelectedProps()}
              />
              <Row>
                {page.map((row: any, i: number) => {
                  prepareRow(row);
                  const [
                    selection,
                    name,
                    payer,
                    memberId,
                    groupId,
                    status,
                    created,
                  ] = row.cells.map((cell: any) => cell.value);

                  const cardImage = row.original?.images?.front?.url;
                  const card_id = row.original?.card_id;
                  const message = row.original?.message;

                  return (
                    <Col xs={12} md={6} xl={4} {...row.getRowProps()}>
                      <Card
                        checked={selection}
                        cardId={card_id}
                        img={cardImage}
                        status={status}
                        name={name}
                        payer={payer}
                        memberId={memberId}
                        groupId={groupId}
                        created={formatDate(created)}
                        message={message}
                        {...row.getToggleRowSelectedProps()}
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row className="g-0">
                <Col>
                  <Pagination className="pagination-tabs justify-content-start">
                    <Pagination.Item
                      disabled={!canPreviousPage}
                      onClick={() => previousPage()}
                    >
                      <FeatherIcon
                        icon="arrow-left"
                        size="1em"
                        className="me-1"
                      />{" "}
                      Prev
                    </Pagination.Item>
                  </Pagination>
                </Col>
                <Col>
                  <Pagination className="pagination-tabs justify-content-center">
                    {pageOptions.map((option: number, index: number) => (
                      <Pagination.Item
                        key={index}
                        active={option === pageIndex}
                        onClick={() => gotoPage(option)}
                      >
                        {option + 1}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </Col>
                <Col>
                  <Pagination className="pagination-tabs justify-content-end">
                    <Pagination.Item
                      disabled={!canNextPage}
                      onClick={() => nextPage()}
                      className="fw-bolder"
                    >
                      Next{" "}
                      <FeatherIcon
                        icon="arrow-right"
                        size="1em"
                        className="ms-1"
                      />
                    </Pagination.Item>
                  </Pagination>
                </Col>
              </Row>
            </div>
          )}
          {Object.keys(selectedRowIds).length > 0 && (
            <Alert
              variant="dark"
              className="list-alert alert-dismissible border"
            >
              <Row className="align-items-center">
                <Col>
                  <Form.Check
                    type="checkbox"
                    label={`${
                      Object.keys(selectedRowIds).length
                    } Selected Cards `}
                    checked
                    disabled
                  />
                </Col>
                <Col xs="auto" className="me-n3">
                  <Button
                    variant="white-20"
                    size="sm"
                    onClick={() =>
                      exportToCSV(
                        cardsData.filter((card) =>
                          selectedFlatRows
                            .map(
                              (selectedFlatRow: any) =>
                                selectedFlatRow.original.card_id
                            )
                            .includes(card.card_id)
                        )
                      )
                    }
                  >
                    CSV
                  </Button>
                  <Button
                    variant="white-20"
                    size="sm"
                    className="ms-1"
                    onClick={() =>
                      exportToJSON(
                        cardsData.filter((card) =>
                          selectedFlatRows
                            .map(
                              (selectedFlatRow: any) =>
                                selectedFlatRow.original.card_id
                            )
                            .includes(card.card_id)
                        )
                      )
                    }
                  >
                    JSON
                  </Button>
                </Col>
              </Row>
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default Cards;
