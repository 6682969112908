import { FC } from "react";
import className from "classnames";
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  NavDropdown,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import Filters from "./Filters";
import { perPageOptions } from "./helpers";

const TableView: FC<any> = ({
  getTableBodyProps,
  perPage,
  handlePerPageClick,
  headerGroups,
  page,
  pageOptions,
  gotoPage,
  canNextPage,
  nextPage,
  prepareRow,
  handleExport,
  canPreviousPage,
  previousPage,
  pageIndex,
  handleUpdateRangeValue,
  setDateFromFilter,
  setDateToFilter,
  dateRangeValue,
  dateFromValue,
  dateToValue,
  setGlobalFilter,
  setPageSize,
}) => {
  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <InputGroup className="input-group-merge input-group-flush input-group-reverse">
              <Form.Control
                type="search"
                placeholder="Search"
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
              <InputGroup.Text>
                <FeatherIcon icon="search" size="1em" />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs="auto" className="me-n3">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={`${perPage} per page`}
              menuVariant="dark"
              className="card__per-page"
            >
              {perPageOptions.map((option: number) => (
                <NavDropdown.Item
                  key={option}
                  onClick={() => {
                    setPageSize(option);
                    handlePerPageClick(option);
                  }}
                >
                  {option} per page
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Col>
          <Col xs="auto">
            <Filters
              setDateRangeFilter={handleUpdateRangeValue}
              setDateFromFilter={setDateFromFilter}
              setDateToFilter={setDateToFilter}
              dateRangeValue={dateRangeValue}
              dateFromValue={dateFromValue}
              dateToValue={dateToValue}
              setGlobalFilter={setGlobalFilter}
            />
          </Col>
          <Col xs="auto">
            <DropdownButton id="dropdown-basic-button" title="Export" size="sm">
              <Dropdown.Item onClick={() => handleExport("csv")}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleExport("json")}>
                JSON
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
      </Card.Header>
      <Table size="sm" className="card-table table-nowrap" hover responsive>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps({ role: null })}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: className(
                        column.className,
                        column.canSort && "is-sortable"
                      ),
                      role: null,
                    })
                  )}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="fs-base" {...getTableBodyProps({ role: null })}>
          {page.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps({ role: null })}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                        role: null,
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Card.Footer className="d-flex justify-content-between">
        <Pagination className="card-pagination pagination-tabs">
          <Pagination.Item
            className="ps-0 pe-4 border-end"
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          >
            <FeatherIcon icon="arrow-left" size="1em" className="me-1" /> Prev
          </Pagination.Item>
        </Pagination>
        <Pagination className="card-pagination pagination-tabs">
          {pageOptions.map((option: any, index: any) => (
            <Pagination.Item
              key={index}
              active={option === pageIndex}
              onClick={() => gotoPage(option)}
            >
              {option + 1}
            </Pagination.Item>
          ))}
        </Pagination>
        <Pagination className="card-pagination pagination-tabs">
          <Pagination.Item
            className="ps-4 pe-0 border-start"
            disabled={!canNextPage}
            onClick={() => nextPage()}
          >
            Next <FeatherIcon icon="arrow-right" size="1em" className="ms-1" />
          </Pagination.Item>
        </Pagination>
      </Card.Footer>
    </Card>
  );
};

export default TableView;
