import {useTheme } from "@aws-amplify/ui-react";

export default function SignInFooter() {
    const { tokens } = useTheme();
  
    return (
        <div>
            <p style={{textAlign:'center', padding: `0 ${tokens.space.medium} ${tokens.space.medium}`}}>
                Existing Customer? <a href='/signin'>Sign In</a>
            </p>
        </div>
    );
  }
