import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import QRCodeCanvas from "qrcode.react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
  Modal,
  FloatingLabel,
  Form,
  Alert
} from "react-bootstrap";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const QrStepOne = (props: { val: string }) => {
  return (
    <>
      <ul className="text-start small text-muted ps-4 mb-0">
        <li>In the Google Authenticator app tap the +</li>
        <li>
          Choose <b className="text-dark">Scan a QR code</b>
        </li>
      </ul>
      <div className="d-flex align-items-center justify-content-center p-5">
        <QRCodeCanvas value={props.val} />
      </div>
    </>
  );
};
export const QrStepTwo = (props: {
  value: string;
  onCodeChange: (e: any) => void;
}) => {
  return (
    <>
      <p>Enter 6 digit code you see in the appp</p>
      <FloatingLabel label="Enter Code">
        <Form.Control
          type="text"
          value={props.value}
          onChange={props.onCodeChange}
          required
        />
      </FloatingLabel>
    </>
  );
};

export default function AccountTwoFactorSecurity() {
  const { user } = useAuthenticator((context) => [context.route]);
  const [qrVal, setQrVal] = useState<string>("");
  const [mfaModal, setMfaModal] = useState(false);
  const [step, setStep] = useState(1);
  const [mfaCode, setMfaCode] = useState("");
  const [currentMfa , setCurrentMfa] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);

  const setUp2FA = async () => {
    try {
      setIsLoading(true);
      let code = await Auth.setupTOTP(user);
      const generatedQrCode =
        "otpauth://totp/" +
        user.attributes?.email +
        "?secret=" +
        code +
        "&issuer=CardScan.ai";
      setQrVal(generatedQrCode);
      setMfaModal(true);
      setIsLoading(false);
    } catch (e: any) {
      setError(e?.message);
      setIsLoading(false);
    }
  };

  const handleMfaNext = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      try {
        setIsLoading(true);
        let tokenResponse = await Auth.verifyTotpToken(user, mfaCode);
        let preferenceResponse = await Auth.setPreferredMFA(user, "TOTP");
        setIsLoading(false);
        setMfaModal(false);
        setMfaCode("");
        setStep(1);
        setFlag(!flag)
        toast.success('2FA Enabled Successfully!', {
          position: "top-right",
          autoClose: 4000,
        });
      } catch (e: any) {
        setError(e?.message);
        setIsLoading(false);
      }
    }
  };

  const setSmsMfa = async ()=>{
    try {
      setIsLoading(true);
      // Set the user's preferred MFA type to SMS
      const response = await Auth.setPreferredMFA(user, 'SMS');
      // If the MFA type was successfully set, display a success message
      if (response === 'SUCCESS') {
        setFlag(!flag)
        toast.success('SMS MFA ENABLED SUCCESSFULLY!', {
          position: "top-right",
          autoClose: 5000,
          });
      }
      setIsLoading(false);
    } catch (e:any) {
      setError(e?.message);
      setIsLoading(false);
    }
  }
  
  const cancelMfa = async ()=>{
    try{
      setIsLoading(true);
     await Auth.setPreferredMFA(user, 'NOMFA');
      setFlag(!flag)
      setIsLoading(false);
    }catch(e:any){
      setError(e?.message);
      setIsLoading(false);
    }

  }

  const getCurrentMfa = async (user: any)=>{
    if (!user) {
      return;
    }

    let data  = await Auth.getPreferredMFA(user, {
      bypassCache: false
    })
    setCurrentMfa(data)
  }

  useEffect(() => {
    getCurrentMfa(user)
  },[flag, user])


  return (
    <>
      <Row className="justify-content-between align-items-center mb-5">
        <Col xs={12} md={9} xl={7}>
          <h2 className="mb-2">Two factor security</h2>
          <p className="text-muted mb-md-0">
            Two-factor authentication adds an additional layer of security to
            your account by requiring more than just a password to log in.
          </p>
        </Col>
      </Row>
      {error ? <Alert variant={"danger"}>{error}</Alert> : ""}
      <Card>
        <Card.Body>
          <ListGroup className="list-group-flush my-n3">
            <ListGroup.Item>
              <Row className="row align-items-center">
                <Col>
                  <h3 className="text-start mb-1">Authenticator app</h3>
                  <small className="text-start d-block text-muted">
                    Authenticate using a code generated by an app installed on
                    your mobile device or computer.
                  </small>
                </Col>
                <Col xs="auto">
                  {currentMfa === "SOFTWARE_TOKEN_MFA" ? (
                    <Button
                      onClick={cancelMfa}
                      variant="danger"
                      size="sm"
                      disabled={isLoading}
                    >
                      Disable
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      onClick={setUp2FA}
                      size="sm"
                    >
                      Enable
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Row className="align-items-center">
                <Col>
                  <h4 className="text-start mb-1">
                    SMS Verification{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Phone number will only be used for verification
                        </Tooltip>
                      }
                    >
                      <span className="text-muted ms-1">
                        <FeatherIcon icon="info" size="1em" />
                      </span>
                    </OverlayTrigger>
                  </h4>
                  <small className="text-start d-block text-muted">
                    Authenticate using text messages sent to your mobile device.
                  </small>
                </Col>
                <Col xs="auto">
                {currentMfa === "SMS_MFA" ? (
                    <Button
                      onClick={cancelMfa}
                      variant="danger"
                      size="sm"
                      disabled={isLoading}
                    >
                      Disable
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      onClick={setSmsMfa}
                      size="sm"
                    >
                      Enable
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroup.Item> */}
          </ListGroup>
        </Card.Body>
      </Card>
      <Modal centered show={mfaModal} onHide={() => setMfaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Setup Authenticator App</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <QrStepOne val={qrVal} />
          ) : (
            <QrStepTwo
              value={mfaCode}
              onCodeChange={(e) => setMfaCode(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === 2 ? (
            <Button variant="secondary" onClick={() => setStep(1)}>
              Back
            </Button>
          ) : (
            ""
          )}
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleMfaNext}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
