export default {
  base: {
    children: [ 'account', 'demo', 'cards'],
    id: 'base',
  },
  account: {
    icon: 'user',
    id: 'account',
    title: 'Account',
    url:'/account/general'
  },
  demo: {
    icon: 'camera',
    id: 'demo',
    title: 'Demo',
    url: '/demo',
  },
  cards: {
    icon: 'layout',
    id: 'cards',
    title: 'Cards',
    url: '/cards',
  }
};