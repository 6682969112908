import { FC } from "react";
import { Stack } from "react-bootstrap";
import IndeterminateCheckbox from "./IndeterminateCheckbox.js";

import "./card.css";

const Card: FC<{
  img?: string;
  status: string;
  name?: string;
  payer?: string;
  memberId?: string;
  groupId?: string;
  created: string;
  message?: string;
  cardId: string;
}> = ({
  img,
  status,
  name,
  payer,
  memberId,
  groupId,
  created,
  message,
  cardId,
  ...rest
}) => (
  <div data-private className="card cardscan__card">
    <IndeterminateCheckbox
      type="checkbox"
      className="form-check-circle card__checkbox"
      {...rest as any}
    />
    <div className="card__header">
      <Stack direction="vertical" className="card__header-container" gap={3}>
        <div className={`card__header-status card__header-status-${status}`}>
          {status}
        </div>
        <img src={img} alt="Insurance Card" className="card__image" />
        <p className="card__header-name">
          {status === "error" ? "Scan Error" : name}
        </p>
      </Stack>
    </div>
    {status === "error" ? (
      <div className="card__body">
        <Stack direction="horizontal">
          <p className="card__section-title">Message</p>
          <p className="card__section-text ms-auto">{message}</p>
        </Stack>
        <hr className="divider" />
        <Stack direction="horizontal">
          <p className="card__section-title">Created</p>
          <p className="card__section-text ms-auto">{created}</p>
        </Stack>
      </div>
    ) : (
      <div className="card__body">
        <Stack direction="horizontal">
          <p className="card__section-title">Payer</p>
          <p className="card__section-text ms-auto">{payer}</p>
        </Stack>
        <hr className="divider" />
        <Stack direction="horizontal">
          <p className="card__section-title">Member ID</p>
          <p className="card__section-text ms-auto">{memberId}</p>
        </Stack>
        <hr className="divider" />
        <Stack direction="horizontal">
          <p className="card__section-title">Group ID</p>
          <p className="card__section-text ms-auto">{groupId}</p>
        </Stack>
        <hr className="divider" />
        <Stack direction="horizontal">
          <p className="card__section-title">Created</p>
          <p className="card__section-text ms-auto">{created}</p>
        </Stack>
      </div>
    )}
  </div>
);

export default Card;
