import FeatherIcon from "feather-icons-react";
import { Link, useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { useContext, useState } from "react";
import {
  Collapse,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import Avatar from "../Avatar";
import nav from "../../data/nav";
import onboarding from "../../data/onboarding";
import Logo from "../../assets/icons/CardScanLogo.svg";
import AvatarPlaceholder from "../../assets/icons/avatar.svg";

import APIKeyContext, { hasKeys } from "../../context/APIKeyContext";

export default function Sidenav({ ...props }) {
  const { apiKeys } = useContext(APIKeyContext);
  const router = useLocation();

  var data = hasKeys(apiKeys) ? nav : onboarding;

  const [activeItemId, setActiveItemId] = useState(() => {
    return Object.keys(data).filter((itemId) => {
      return data[itemId].url === router.pathname;
    })[0];
  });

  // const [modalSearchVisible, setModalSearchVisible] = useState(false);
  // const [modalNotificationsVisible, setModalNotificationsVisible] = useState(false);

  function isExpanded(itemId) {
    if (activeItemId === itemId) {
      return true;
    }

    return isParent(itemId);
  }

  function isParent(itemId) {
    const item = data[itemId];

    if (!item.children) {
      return false;
    }

    if (item.children.includes(activeItemId)) {
      return true;
    }

    let result = false;

    item.children.forEach((childId) => {
      if (isParent(childId)) {
        result = true;
      }
    });

    return result;
  }

  function getItems(ids) {
    return ids.map(function (id, index) {
      const item = data[id];

      return (
        <div key={id}>
          {index > 0 && <hr className="navbar-divider" />}
          {item.title && <h6 className="navbar-heading">{item.title}</h6>}
          {item.children && <Nav>{getSubitems(item.children, id, ids)}</Nav>}
        </div>
      );
    });
  }

  function getSubitems(ids, parentId, arr) {
    return ids.map(function (id) {
      const item = data[arr.splice(arr.indexOf(id), 1)];
      return (
        <Nav.Item key={id}>
          {item.children ? (
            <>
              <Nav.Link onClick={() => handleClick(id, parentId)} role="button">
                {item.icon && <FeatherIcon icon={item.icon} size="17" />}
                {item.title}
                <FeatherIcon
                  icon="chevron-down"
                  size="1em"
                  className={`ms-auto nav-chevron ${
                    isExpanded(id) && "active"
                  }`}
                  {...props}
                />
              </Nav.Link>
              <Collapse in={isExpanded(id)}>
                <div>
                  <div className="nav nav-sm flex-column">
                    {getSubitems(item.children, id, arr)}
                  </div>
                </div>
              </Collapse>
            </>
          ) : (
            <Nav.Link
              as={Link}
              to={item.url}
              active={router.pathname === item.url}
              onClick={() => handleClick(id, parentId)}
            >
              {item.icon && <FeatherIcon icon={item.icon} size="17" />}
              {item.title}
            </Nav.Link>
          )}
        </Nav.Item>
      );
    });
  }

  function handleClick(itemId, parentId, setVisible) {
    setActiveItemId(isExpanded(itemId) ? parentId : itemId);

    if (setVisible) {
      setVisible(false);
    }
  }

  const toggler = <Navbar.Toggle />;

  const brand = (
    <Link to="/">
      <Navbar.Brand>
        <img className="navbar-brand-img" src={Logo} alt="..." />
      </Navbar.Brand>
    </Link>
  );

  const SignOut = (event) => {
    event.preventDefault();
    Auth.signOut()
      .then(() => {
        window.open("/signin", "_self");
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const user = (
    <Dropdown align="end" className="d-md-none">
      <Dropdown.Toggle as={Avatar} size="sm" role="button">
        <Avatar.Image
          className="rounded-circle"
          src={AvatarPlaceholder}
          alt="Acconut Settings"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <Dropdown.Item as={Link} to='/profile'>Profile</Dropdown.Item>
        <Dropdown.Item>Settings</Dropdown.Item>
        <Dropdown.Divider /> */}
        <Dropdown.Item onClick={SignOut} as={Link} to="/signin">
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const form = (
    <form className="mt-4 mb-3 d-md-none">
      <InputGroup className="input-group-merge input-group-reverse input-group-rounded">
        <Form.Control type="search" placeholder="Search" aria-label="Search" />
        <InputGroup.Text>
          <FeatherIcon icon="search" size="15" />
        </InputGroup.Text>
      </InputGroup>
    </form>
  );

  const footer = (
    <div className="navbar-user d-none d-md-flex">
      <Dropdown drop="up">
        <Dropdown.Toggle as={Avatar} size="sm" role="button">
          <Avatar.Image
            className="rounded-circle"
            src={AvatarPlaceholder}
            alt="Acconut Settings"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.Item>Profile</Dropdown.Item>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item onClick={SignOut} as={Link} to="/signin">
            Logout
          </Dropdown.Item>
          {/* <Link to="/sign-in">
            <Dropdown.Item>Logout</Dropdown.Item>
          </Link> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const collapse = (
    <Navbar.Collapse {...props}>
      {/* {form} */}
      {apiKeys == null ? (
        <ContentLoader
          speed={2}
          width={250}
          height={400}
          viewBox="0 0 250 400"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="5" y="0" rx="3" ry="3" width="200" height="30" />
          <rect x="5" y="50" rx="3" ry="3" width="200" height="30" />
          <rect x="5" y="100" rx="3" ry="3" width="200" height="30" />
        </ContentLoader>
      ) : (
        getItems(Object.keys(data))
      )}
      <div className="mt-auto mb-md-4" />
      {footer}
    </Navbar.Collapse>
  );

  return (
    <>
      <Navbar
        expand="md"
        className="navbar-vertical fixed-start"
        collapseOnSelect={true}
        {...props}
      >
        <Container fluid>
          {toggler}
          {brand}
          {user}
          {collapse}
        </Container>
      </Navbar>
      {/* <ModalSearch visible={modalSearchVisible} onDismiss={() => setModalSearchVisible(false)} />
      <ModalNotifications visible={modalNotificationsVisible} onDismiss={() => setModalNotificationsVisible(false)} /> */}
    </>
  );
}
