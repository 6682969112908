import { CardState } from "@cardscan.ai/insurance-cardscan-react";
import React, { SetStateAction, Dispatch } from "react";
import Card from "../model/Card/Card";

const CardContext = React.createContext<{
  cardDetails: Card;
  cardImage: string;
  setCardDetails: Dispatch<SetStateAction<Card>>;
  setCardImage: Dispatch<SetStateAction<string>>;
}>({
  cardDetails: {
    cardId: '',
    state: CardState.pending
  },
  cardImage: "",
  setCardDetails: () => {},
  setCardImage: () => {},
});

export default CardContext;
