import React from "react";
import { Col, Nav, Row } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";
import Header from "../Header";

const AccountHeader = ({ ...props }) => {
  let { tab } = useParams();

  // console.log(tab, "==========================================");
  return (
    <Header className="mt-md-5" {...props}>
      <Header.Body>
        <Row className="align-items-center">
          <Col>
            <Header.Pretitle>Overview</Header.Pretitle>
            <Header.Title>Account</Header.Title>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Header.Tabs className="nav-overflow">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/account/general"
                  active={tab === 'general' ? true : false}
                >
                  General
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/account/security"
                  active={tab === 'security' ? true : false}
                >
                  Security
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/account/billing"
                  active={tab === 'billing' ? true : false}
                >
                  Billing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/account/keys"
                  active={tab === 'keys' ? true : false}
                >
                  API Keys
                </Nav.Link>
              </Nav.Item>

            </Header.Tabs>
          </Col>
        </Row>
      </Header.Body>
    </Header>
  );
};

export default React.memo(AccountHeader);
