import { useEffect, useRef, useContext, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, Link } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import ReactSpinKit from "react-spinkit";
import { CardScanApi, CardState, CardScanModel } from "@cardscan.ai/insurance-cardscan-react";
import "./button.css";
import "./card.css";
import "./card-company.css";
import "./home.css";

import Picture from "../../assets/icons/picture.svg";
import Upload from "../../assets/icons/upload.svg";
import United from "../../assets/icons/united.svg";
import Anthem from "../../assets/icons/anthem.svg";
import Humana from "../../assets/icons/humana.svg";
import Download from "../../assets/icons/download.svg";

import APIKeyContext from "../../context/APIKeyContext";
import CardContext from "../../context/cardContext";
import CardScanApiContext from "../../context/cardScanApiContext";

import Banner from "../../components/Banner";
import Results from "../../components/Results";

CardScanModel.warm();

const Home = () => {
  const { cardDetails, setCardDetails, cardImage, setCardImage } =
    useContext(CardContext);
  const [isLoading, setIsLoading] = useState(false);

  const { apiKeys } = useContext(APIKeyContext);

  const { cardScanApi, setCardScanApi } = useContext(CardScanApiContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handleUpload = useCallback(
    (image: File) => {
      setIsLoading(true);
      cardScanApi?.uploadCardImage(image).then((cardID: any) => {
        cardScanApi?.subscribe(cardID, (cardDetails: any) => {
          setCardDetails(cardDetails);
          if (cardDetails.state === CardState.completed) {
            cardScanApi?.getCard(cardID);
            cardScanApi?.getCardImage(cardID).then((res: any) => {
              setIsLoading(true);
              setCardImage(res.data.url);
            });
          }
        });
      });
    },
    [cardScanApi, setCardDetails, setCardImage]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      handleUpload(acceptedFiles[0]);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (apiKeys && apiKeys.sandbox && apiKeys.sandbox !== "" && !cardScanApi) {
      setCardScanApi(
        new CardScanApi({
          live: false,
          sessionToken: apiKeys.sandbox,
        })
      );
    }
  }, [setCardScanApi, apiKeys]);

  return (
    <div>
      <Banner />
      {apiKeys && apiKeys.sandbox ? '' :
        <Alert className="mt-5" variant="warning" style={{margin: '0 10%'}}>
          The demo is disabled until this account has been provisioned.
        </Alert>
      }
      {cardDetails && cardImage ? (
        <Results
          cardDetails={cardDetails}
          cardImage={cardImage}
          handleClick={() => {
            setCardImage("");
            setCardDetails({
              cardId: "",
              state: CardState.pending,
            });
            setIsLoading(false);
          }}
        />
      ) : (
        <main className="card-scan--main">
          <div className="card-scan--card left">
            <img src={Picture} alt="A camera with a purple background" />
            <h4>Device Camera</h4>
            <p>Scan your insurance card using the device's camera</p>
            <button
              disabled={!(apiKeys && apiKeys.sandbox)}
              className="card-scan--button secondary disabled"
              onClick={() => navigate("/scan")}
            >
              Open Camera
            </button>
          </div>
          <div
            className="card-scan--card right"
            {...getRootProps()}
            style={{
              background: isDragActive ? "rgba(22, 27, 45, 0.1)" : "#FFF",
            }}
          >
            {isLoading ? (
              <div className="card-scan--spinner-wrapper">
                <ReactSpinKit name="ball-spin-fade-loader" color="#335eea" fadeIn="quarter"/>
                <p>Card is being processed...</p>
              </div>
            ) : (
              <>
                <img
                  src={isDragActive ? Download : Upload}
                  alt="A white file with a blue background"
                />
                <h4 style={{ visibility: isDragActive ? "hidden" : "visible" }}>
                  Upload Photo
                </h4>
                <p style={{ color: isDragActive ? "initial" : "" }}>
                  {isDragActive
                    ? "Drag and drop an image here to upload"
                    : "Select an image of your insurance card to upload"}
                </p>
                {!isDragActive && (
                  <label htmlFor="upload">
                    <button
                      disabled={!(apiKeys && apiKeys.sandbox)}
                      className="card-scan--button primary"
                      onClick={() => {
                        inputRef?.current?.click();
                      }}
                    >
                      Upload
                    </button>
                    <input
                      type="file"
                      id="upload"
                      disabled={!(apiKeys && apiKeys.sandbox)}
                      style={{ display: "none" }}
                      ref={inputRef}
                      onChange={(event) => {
                        const image = event?.target?.files?.[0];
                        if (image) handleUpload(image);
                      }}
                      {...getInputProps()}
                    />
                  </label>
                )}
              </>
            )}
          </div>
        </main>
      )}
      <hr />
      <section>
        <h2 className="card-scan--subtitle">
          Please don't scan <strong>real</strong> insurance cards
        </h2>
        <p className="card-scan--text-primary">
          While our platform is secure and HIPAA compliant, this demo is not. We
          need to sign a business associate agreement (BAA) before processing
          protected health information (PHI/PII).
        </p>
      </section>
      <div className="card-scan--companies">
        <div className="card-scan--card-company">
          <Link to="/cards/uhc.jpg" target="_blank" download>
            <img src={United} alt="United logo" />
          </Link>
        </div>
        <div className="card-scan--card-company">
          <Link to="/cards/humana.jpg" target="_blank" download>
            <img src={Humana} alt="Humana logo" />
          </Link>
        </div>
        <div className="card-scan--card-company">
          <Link to="/cards/cigna.jpg" target="_blank" download>
            <img src={Anthem} alt="Anthem logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
