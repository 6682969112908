import {Heading, useTheme } from "@aws-amplify/ui-react";

export default function SignInHeader() {
    const { tokens } = useTheme();
  
    return (
        <div>
        <Heading textAlign={'center'} level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
            Sign in to your Account
        </Heading>
        <p style={{textAlign: 'center', padding: `${tokens.space.xs} ${tokens.space.xl} 0`}}>
            New Customer? <a href='/onboarding?start=true'>Create an Account</a>
        </p>
        </div>
    );
  }
  