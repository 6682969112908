export const filterByDateRange = (data: any[], range?: string) => {
  if (!range || range === "undefined") return data;

  if (range === "7days") {
    return data.filter((card) => {
      const rangeDate = new Date(new Date().setDate(new Date().getDate() - 7));
      rangeDate.setHours(0);
      rangeDate.setMinutes(0);
      const cardDate = new Date(card.created_at);

      return cardDate.getTime() >= rangeDate.getTime();
    });
  }

  if (range === "24hours") {
    return data.filter((card) => {
      const rangeDate = new Date(new Date().setDate(new Date().getDate() - 1));
      const cardDate = new Date(card.created_at);

      rangeDate.setHours(0);
      rangeDate.setMinutes(0);

      return cardDate.getTime() >= rangeDate.getTime();
    });
  }

  if (range === "30days") {
    return data.filter((card) => {
      const rangeDate = new Date(new Date().setDate(new Date().getDate() - 30));
      const cardDate = new Date(card.created_at);

      rangeDate.setHours(0);
      rangeDate.setMinutes(0);

      return cardDate.getTime() >= rangeDate.getTime();
    });
  }

  return data;
};

export const filterByDateFrom = (data: any[], from?: string) => {
  if (!from) return data;

  const fromDate = new Date(from);

  fromDate.setHours(0);
  fromDate.setMinutes(0);

  return data.filter((card) => {
    const cardDate = new Date(card.created_at);
    return cardDate.getTime() >= fromDate.getTime();
  });
};

export const filterByDateTo = (data: any[], to?: string) => {
  if (!to) return data;

  const toDate = new Date(to);

  toDate.setHours(23);
  toDate.setMinutes(59);

  return data.filter((card) => {
    const cardDate = new Date(card.created_at);
    return cardDate.getTime() <= toDate.getTime();
  });
};

export const filterByStatus = (data: any[], status: string) =>
  data.filter((el) => el.state === status);

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  return `${date.getDate()} ${date.toLocaleString("en-US", {
    month: "short",
  })}, ${date.getFullYear()}`;
};
