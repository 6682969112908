import { useState, useEffect } from "react";
import { Auth, I18n } from 'aws-amplify';
import { Flex, Image, useTheme, Heading, Grid, View, PasswordField, Text, Link, TextField, CheckboxField } from "@aws-amplify/ui-react";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';
import SignUpFooter from './SignUpFooter'
import { log } from "../../widgets/DashboardLog"

const SignUp = () => {
  const { tokens } = useTheme();
  const [email, setEmail] = useState<string | null>(null);
  const { route } = useAuthenticator((context) => [context.route]);
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopstate = () => {
      let local: any = localStorage.getItem("formValues");
      let search = 'email=' + encodeURIComponent(JSON.parse(local).email)
      if (route === "confirmSignUp" || route === "setup" || route === "idle") {
        navigate(`/signUp?${search}`)
        window.location.reload()
        // window.location.href = `/signUp?${search}`;
      }
    };

    // Add a popstate event listener to detect when the back button is clicked
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (route === 'authenticated') {
      navigate("/setup");
    }
  }, [route]);


  useEffect(() => {
    var search = new URLSearchParams(location.search);
    setEmail(search.get('email'))

  }, [location, setEmail]);


  useEffect(() => {
    var search = new URLSearchParams(location.search);
    log('SignUp Landing',
      [{ 'title': 'email', 'value': search.get('email') },
      { 'title': 'search', 'value': location.search }])
  }, []);

  const services: any = {

    async validateCustomSignUp(formData: any) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions and Privacy Policy',
        };
      }
    },
    async handleSignUp(formData: any) {
      let { username, password, attributes } = formData;
      // custom username
      const search = new URLSearchParams(window.location.search);
      const _email = search.get('email');

      if (username === undefined && _email) {
        //defaultValue are not getting picked up so we set it manually 
        username = _email
      }
      try {
        log('SignUp',
          [{ 'title': 'email', 'value': username },
          { 'title': 'search', 'value': location.search }])
      } catch (error) {
        console.log(error);
      }

      //log all search attrs for user source
      attributes['custom:user_source'] = location.search;
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      })
    },

    async handleConfirmSignUp(data: any) {
      let { username, code } = data;
      return Auth.confirmSignUp(username,
        code
      )
    }
  };

  I18n.putVocabulariesForLanguage('en', {
    'Create Account': 'Continue', // Tab header
  });

  const terms = (
    <Text
      fontSize="1em">
      I agree to the <Link
        href="https://www.cardscan.ai/terms-of-service"
        isExternal={true}>Terms & Conditions
      </Link> and <Link
        href="https://www.cardscan.ai/privacy"
        isExternal={true}
      >
        Privacy Policy
      </Link>
    </Text>
  );

  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <Flex
        backgroundColor={tokens.colors.background.secondary}
        justifyContent="center"
      >

        <Authenticator
          loginMechanisms={['email']}
          initialState="signUp"
          services={services}
          signUpAttributes={[]}
          components={{
            Header: AuthHeader,
            SignUp: {
              FormFields() {
                const { validationErrors } = useAuthenticator();
                //how can i log validation errors?
                return (
                  <>
                    <Heading textAlign={'center'} level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
                      Create an Account
                    </Heading>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <TextField
                      defaultValue={email || ''}
                      label=''
                      placeholder="Work Email"
                      name='email'
                      type="email"
                      hasError={!!validationErrors.email}
                      errorMessage={Array.isArray(validationErrors.email) ? validationErrors.email[0] : validationErrors.email}
                      autoComplete="username"
                      isRequired={true}
                    />

                    <PasswordField
                      name='password'
                      label=''
                      placeholder="Password"
                      autoComplete="new-password"
                      isRequired={true}
                      hasError={!!validationErrors.password}
                      errorMessage={Array.isArray(validationErrors.password) ? validationErrors.password[0] : validationErrors.password}
                    />
                    <PasswordField
                      name='confirm_password'
                      label=''
                      placeholder='Confirm Password'
                      autoComplete="new-password"
                      isRequired={true}
                      errorMessage={Array.isArray(validationErrors.confirm_password) ? validationErrors.confirm_password[0] : validationErrors.confirm_password}
                      hasError={!!validationErrors.confirm_password}
                    />
                    <CheckboxField
                      errorMessage={validationErrors.acknowledgement as string}
                      // hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                      isRequired={true}
                      label={terms}
                    />
                  </>
                );
              },
              Footer: SignUpFooter
            },
            Footer: AuthFooter
          }}
        >
        </Authenticator>
      </Flex>
      <View height="100vh">
        <Image
          className="auth-image"
          alt=''
          src="https://www.cardscan.ai/assets/img/covers/main-top.jpg"
          height="100%"
          objectFit="cover"
        />
      </View>
    </Grid>
  );
}

export default SignUp;