import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify, Auth } from 'aws-amplify';
import Routes  from "./routes";
import amplifyConfig, { cognitoConfig } from './config/amplify';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import "./styles/theme.scss";
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import LogRocket from 'logrocket';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.debug("Disabling logging in devlopment mode");
} else {
  LogRocket.init('sdpsgy/cardscan', {
    network: {
      requestSanitizer: request => {
        //don't track request or response bodies -- safer for keys and PHI
        if (request.headers['authorization']) {
          request.headers['authorization'] = '';
        }
        request.body = '';
        return request;
      },
      responseSanitizer: response => {
        response.body = '';
        return response;
       },
    },
  });
}

Amplify.configure(amplifyConfig);
Auth.configure(cognitoConfig)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

const AppWrapper = () => {

  return (
      <>
        <AmplifyProvider>
          <Authenticator.Provider >
            <Router>
              <Routes />
            </Router>
          </Authenticator.Provider>
        </AmplifyProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="light"
        /><ToastContainer />
      </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
