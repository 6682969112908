export const keywords = [
  "message",
  "details.payer_name.value",
  "created_at",
  "details.member_number.value",
  "details.group_number.value",
  "details.rx_bin.value",
  "details.rx_pcn.value",
  "details.rx_group.value",
  "details.member_name.value",
  "details.dependent_names.value",
  "details.plan_name.value",
  "details.plan_id.value",
  "details.client_name.value",
  "details.start_date.value",
  "details.card_specific_id.value",
  "state",
];
