import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

const IndeterminateCheckbox = ({ indeterminate, ...rest }) => {
  const defaultRef = useRef();

  useEffect(() => {
    defaultRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return <Form.Check ref={defaultRef} {...rest} />;
};

export default IndeterminateCheckbox;
