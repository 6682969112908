import { Col, Container, Row } from "react-bootstrap";

import { useParams } from "react-router-dom";
import AccountHeader from "./Header";
import General from "./General";
import Security from "./Security";
import Billing from "./Billing";
import APIKeys from "./APIKeys";

export default function Account() {
  let { tab } = useParams<{ tab?: string }>();

  let TabComponent;

  switch(tab) {
    case "general":
      TabComponent = General;
      break;
    case "security":
      TabComponent = Security;
      break;
    case "billing":
      TabComponent = Billing;
      break;
    case "keys":
      TabComponent = APIKeys;
      break;
    default:
      TabComponent = General; // Default to General if no match
  }

  return (
    <div className="main-content">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={8}>
            <AccountHeader />
            <TabComponent />
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
